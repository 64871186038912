export const relitixProductionFilterTooltip = `
<li><strong>Total $ Production LTM</strong>
  <ul>
    <li>Sales production volume over the last twelve months.</li>
  </ul>
</li>
<li><strong>Total # Production LTM</strong>
  <ul>
    <li>Total units sold by an agent on either side of a transaction in the last twelve months.</li>
  </ul>
</li>
<li><strong>$ Change</strong>
  <ul>
    <li>Sales volume production in the last twelve months compared to the prior twelve months on a dollar basis.</li>
  </ul>
</li>
<li><strong>% Change</strong>
  <ul>
    <li>Sales volume production in the last twelve months compared to the prior twelve months on a percentage basis.</li>
  </ul>
</li>
<li><strong>Average Sales Price</strong>
  <ul>
    <li>The average sales price of all transactions sold by the agent in the last twelve months.</li>
  </ul>
</li>
`;

export const relitixProductionInsightsFilterTooltip = `
<li>
  These parameters require a Relitix subscription 
  (details <a class="text-green" href="https://info.getbrokerkit.com/knowledge/hc/en-us/articles/4464878235533-how-to-configure-the-relitix-integration-in-brokerkit" target="_blank">here</a>).
</li>
<li><strong>Rookie Rating</strong>
  <ul>
    <li>This rating indicates the probability that this agent will still be in the business in their fourth year and that they'll be closing more than 3 million in volume.</li>
  </ul>
</li>
<li><strong>Current Switch Risks</strong>
  <ul>
    <li>It compares this agent's behavior to the behavior of agents who have switched offices and gives us the likelihood that this agent will switch offices in the next three months.</li>
    <li><strong>Green:</strong> The agent is less likely than the average agent to switch offices.</li>
    <li><strong>Yellow:</strong> They are 5 times more likely than the average agent to switch offices in the next 3 months.</li>
    <li><strong>Red:</strong> They are 10 times more likely than the average agent to switch offices in the next 3 months.</li>
  </ul>
</li>
<li><strong>Listing Effectiveness Grade</strong>
  <ul>
    <li>Each agent is graded based on how well they handled their listings over the last 6 to 18 months. All the agents in the MLS are graded on a curve. The top 20% of agents in the MLS get an A, and the bottom 20% get an F.</li>
  </ul>
</li>
<li><strong>Listing Effectiveness Impact</strong>
  <ul>
    <li>Assigns a dollar amount to this agent’s effectiveness with listings by comparing the volume they closed on their listings to what an average agent would have closed for those same listings. If there is a high negative number, this agent most likely had a high-priced listing canceled or expired.</li>
  </ul>
</li>
<li><strong>Five-Year Batting Average</strong>
  <ul>
    <li>This shows the number of listings the agent has held over the last five years and the percentage of those that closed.</li>
  </ul>
</li>
<li><strong>Five-Year Listing Count</strong>
  <ul>
    <li>The number of listings taken between 6 and 66 months ago. Co-listing agents are not counted.</li>
  </ul>
</li>
`;

export const relitixProductionLocationFilterTooltip = `
<li>
  These parameters require a Relitix subscription 
  (details <a class="text-green" href="https://info.getbrokerkit.com/knowledge/hc/en-us/articles/4464878235533-how-to-configure-the-relitix-integration-in-brokerkit" target="_blank">here</a>).
</li>
<li><strong>Closest Office Name</strong>
  <ul>
    <li>
      The office of your company which is located the closest to the geographical center of an agent’s historical listings and sales activity. This determines the best-fit office location for a prospective agent recruit.
    </li>
  </ul>
</li>
<li><strong>Closest Office Address</strong>
  <ul>
    <li>Address of your closest office.</li>
  </ul>
</li>
<li><strong>Distance to Closest Office</strong>
  <ul>
    <li>
      This is the straight-line distance from the geographical center of the agent’s historical listing and sale activity to your closest office.
    </li>
  </ul>
</li>
<li><strong>Distance to Current Office</strong>
  <ul>
    <li>
      This is the straight-line distance from the geographical center of the agent’s historical listing and sale activity to their current office. Large values here indicate agents working in virtual offices.
    </li>
  </ul>
</li>
<li><strong>Office Postal Code</strong>
  <ul>
    <li>Agent's office mailing zip code.</li>
  </ul>
</li>
<li><strong>Center of Production Postal Code</strong>
  <ul>
    <li>The zip code that is the center of the agent’s production.</li>
  </ul>
</li>
`;

export const relitixProductionDatesFilterTooltip = `
<li>
  These parameters require a Relitix subscription 
  (details <a class="text-green" href="https://info.getbrokerkit.com/knowledge/hc/en-us/articles/4464878235533-how-to-configure-the-relitix-integration-in-brokerkit" target="_blank">here</a>).
</li>
<li><strong>First Transaction at Current Office</strong>
  <ul>
    <li>
      The date of the first MLS transaction (listing or sale) at the agent's current office. Note that Relitix only loads back to 2011 for most MLSs.
    </li>
  </ul>
</li>
<li><strong>First Transaction at MLS</strong>
  <ul>
    <li>
      The date of the first MLS transaction record in which the agent appears, regardless of office. Note that this is limited by the history loaded in Relitix’s server and will not be accurate beyond the first date of complete data for the MLS in question.
    </li>
  </ul>
</li>
`;
