import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFeatureFlagVariantKey } from 'posthog-js/react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import { captureEvent } from '~/helpers/capture_posthog_event';
import Tooltipable from '~/components/effects/tooltipable';
import SettingsUpgradePopover from './SettingsUpgradePopover';
import CampaignStepsTab from './CampaignStepsTab';
import CampaignReportingTab from './CampaignReportingTab';
import CampaignSettingsTab from './CampaignSettingsTab';

const CampaignTabs = ({
  campaign,
  campaignSettings,
  loadingCampaign,
  campaignErrors,
  errors,
  validCampaign,
  selectingTemplate,
  onCampaignStateChange,
  history,
  setCampaignErrors,
  setErrors,
}) => {
  const [activeKey, setActiveKey] = useState('steps');
  const disabledTabs = ['reporting'];

  const featureFlagVariant = useFeatureFlagVariantKey(process.env.ENABLE_CAMPAIGN_SETTINGS);
  const showSettingsTab = featureFlagVariant !== 'not_displayed';
  const showUpgradeCTA = featureFlagVariant === 'upgrade_cta';

  const handleSelect = (key, event) => {
    if (disabledTabs.includes(key) || (key === 'settings' && showUpgradeCTA)) {
      event.preventDefault();
      return;
    }

    if (key === 'settings') {
      captureEvent('campaign_settings_tab');
    }

    setActiveKey(key);
  };

  const handleSettingsChange = (settings) => {
    onCampaignStateChange({
      campaignSettings: {
        ...settings,
      },
    });
  };

  return (
    <Tabs
      id="campaign-tabs"
      activeKey={activeKey}
      onSelect={handleSelect}
      className="w-100 data-tabs clearfix"
    >
      <Tab eventKey="steps" title="Steps">
        <CampaignStepsTab
          campaign={campaign}
          campaignSettings={campaignSettings}
          loadingCampaign={loadingCampaign}
          campaignErrors={campaignErrors}
          validCampaign={validCampaign}
          selectingTemplate={selectingTemplate}
          onCampaignStateChange={onCampaignStateChange}
          history={history}
          setCampaignErrors={setCampaignErrors}
          setErrors={setErrors}
        />
      </Tab>

      <Tab
        eventKey="reporting"
        title={(
          <Tooltipable text="Coming Soon!" placement="top">
            <span className="text-grey">
              Reporting
            </span>
          </Tooltipable>
        )}
      >
        <CampaignReportingTab />
      </Tab>

      {showSettingsTab && (
        <Tab
          eventKey="settings"
          title={
            showUpgradeCTA ? (
              <SettingsUpgradePopover placement="bottom">
                {({ togglePopover }) => (
                  <span
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      togglePopover(e);
                    }}
                  >
                    Settings
                  </span>
                )}
              </SettingsUpgradePopover>
            ) : (
              'Settings'
            )
          }
        >
          {!showUpgradeCTA && (
            <CampaignSettingsTab
              campaign={campaign}
              errors={errors}
              onSettingsChange={handleSettingsChange}
            />
          )}
        </Tab>
      )}
    </Tabs>
  );
};

CampaignTabs.defaultProps = {
  campaign:         null,
  campaignSettings: null,
  errors:           null,
};

CampaignTabs.propTypes = {
  campaign:              PropTypes.shape({}),
  campaignSettings:      PropTypes.shape({}),
  errors:                PropTypes.shape({}),
  history:               PropTypes.shape({}).isRequired,
  loadingCampaign:       PropTypes.bool.isRequired,
  validCampaign:         PropTypes.bool.isRequired,
  selectingTemplate:     PropTypes.bool.isRequired,
  onCampaignStateChange: PropTypes.func.isRequired,
  setCampaignErrors:     PropTypes.func.isRequired,
};

export default CampaignTabs;
