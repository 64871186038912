import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { captureEvent } from '~/helpers/capture_posthog_event';
import Tooltipable from '~/components/effects/tooltipable';

const unenrollOnTextReplyTooltip = 'When a contact replies to any text, the campaign subscription for that contact will pause. Other contacts in the campaign will continue as scheduled. Deselect this option if you want contacts to remain subscribed to the campaign even after they reply.';

const CampaignSettingsTab = ({ campaign, errors, onSettingsChange }) => {
  const [unenrollOnTextReply, setUnenrollOnTextReply] = useState(false);
  const [unenrollOnEmailReply, setUnenrollOnEmailReply] = useState(false);

  useEffect(() => {
    if (campaign) {
      setUnenrollOnTextReply(
        campaign.pause_on_text_reply !== undefined
          ? campaign.pause_on_text_reply
          : true,
      );
      setUnenrollOnEmailReply(
        campaign.pause_on_email_reply !== undefined
          ? campaign.pause_on_email_reply
          : true,
      );
    } else {
      setUnenrollOnTextReply(true);
      setUnenrollOnEmailReply(true);
    }
  }, [campaign]);

  const handleTextReplyChange = (e) => {
    const newValue = e.target.checked;
    setUnenrollOnTextReply(newValue);
    onSettingsChange({
      pause_on_text_reply: newValue,
    });

    captureEvent('campaign_settings_reply_automation_text', {
      value: newValue,
    });
  };

  return (
    <div className="mt-4">
      <div className="my-3">
        {errors && (
          <div className="alert alert-danger">
            There are issues in the Steps tab. Please review and correct them before saving.
          </div>
        )}
      </div>

      <p className="font-weight-bold mb-3">Managing Replies</p>

      <div className="mb-2">
        <label
          className="mr-2"
          htmlFor="unenrollOnTextReply"
        >
          When a contact replies to any text &rarr; Pause the campaign for this contact
        </label>
        <input
          type="checkbox"
          id="unenrollOnTextReply"
          checked={unenrollOnTextReply}
          onChange={handleTextReplyChange}
        />

        <Tooltipable trigger="click" text={unenrollOnTextReplyTooltip} placement="right">
          <FontAwesomeIcon
            icon={['fal', 'fa-info-circle']}
            className="text-secondary ml-1"
            size="xs"
          />
        </Tooltipable>
      </div>

      <Tooltipable text="Coming Soon!" placement="right">
        <div className="mb-2">
          <label
            className="mr-2"
            htmlFor="unenrollOnEmailReply"
          >
            When a contact replies to any email &rarr; Pause the campaign for this contact
          </label>
          <input
            type="checkbox"
            id="unenrollOnEmailReply"
            checked={unenrollOnEmailReply}
            disabled
          />
        </div>
      </Tooltipable>
    </div>
  );
};

CampaignSettingsTab.defaultProps = {
  campaign: null,
  errors:    null,
};

CampaignSettingsTab.propTypes = {
  campaign: PropTypes.shape({
    unenrollOnTextReply:  PropTypes.bool,
    unenrollOnEmailReply: PropTypes.bool,
  }),
  errors: PropTypes.shape({}),
};

export default CampaignSettingsTab;
