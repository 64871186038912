import ActionHandler    from '~/components/forms/HtmlEditors/mentions/ActionHandler';
import { captureEvent } from '~/helpers/capture_posthog_event';

export default class AutoCompleteEvents {
  constructor(ed, aCInstance) {
    this.editor = ed;
    this.aCInstance = aCInstance;
    this.actionHandler = new ActionHandler(ed, aCInstance);
    this.cleanUp = aCInstance.cleanUp;
  }

  bindEvents = () => {
    this.editor.on('keyup', this.rteKeyUp.bind(this));
    this.editor.on('keydown', this.rteKeyDown.bind(this));
    this.editor.on('click', this.rteClicked.bind(this));

    document.body.addEventListener('click', this.rteLostFocus.bind(this));

    this.editor.getWin().addEventListener('scroll', this.rteScroll = () => { this.cleanUp(true); });
    this.addParentScrollListener(this.editor.getContainer());
  }

  unbindEvents = () => {
    this.editor.off('keyup', this.rteKeyUp);
    this.editor.off('keydown', this.rteKeyDown);
    this.editor.off('click', this.rteClicked);

    document.body.removeEventListener('click', this.bodyClickProxy);
    this.removeParentScrollListener(this.editor.getContainer());

    if (this.editor.getWin()) {
      this.editor.getWin().removeEventListener('scroll', this.rteScroll);
    }
  }

  rteKeyUp = (e) => {
    switch (e.which || e.keyCode) {
      case 40: // DOWN ARROW
      case 38: // UP ARROW
      case 16: // SHIFT
      case 17: // CTRL
      case 18: // ALT
        break;
      case 8: // BACKSPACE
        if (this.aCInstance.query === '') {
          this.cleanUp(true);
        } else {
          this.actionHandler.lookup();
        }
        break;

      case 9: // TAB
      case 13: { // ENTER
        const item = this.aCInstance.dropdown !== undefined ? this.aCInstance.dropdown.querySelector('li.active') : null;

        if (item !== null) {
          this.actionHandler.select(item.dataset);
          this.cleanUp(false);
        } else {
          this.cleanUp(true);
        }
        break;
      }
      case 27: // ESC
        this.cleanUp(true);
        break;

      default:
        this.actionHandler.lookup();
    }
  }

  rteKeyDown = (e) => {
    switch (e.which || e.keyCode) {
      case 9: // TAB
      case 13: // ENTER
      case 27: // ESC
        e.preventDefault();
        break;
      case 38: // UP ARROW
        e.preventDefault();
        if (this.aCInstance.dropdown !== undefined) {
          this.actionHandler.highlightPreviousResult();
        }
        break;
      case 40: // DOWN ARROW
        e.preventDefault();
        if (this.aCInstance.dropdown !== undefined) {
          this.actionHandler.highlightNextResult();
        }
        break;
      default:
    }

    e.stopPropagation();
  }

  rteClicked = (e) => {
    const { target: { parentNode } } = e;

    if (this.aCInstance.hasFocus && parentNode.getAttribute?.('id') !== 'autocomplete-searchtext') {
      this.cleanUp(true);
    }
  }

  rteLostFocus = () => {
    if (this.aCInstance.hasFocus) {
      this.cleanUp(true);
    }
  };

  autoCompleteClick = (e) => {
    const item = e.target.closest('li')?.dataset;
    if (item && Object.keys(item).length !== 0) {
      this.actionHandler.select(item);
      this.cleanUp(false);
    }
    e.stopPropagation();
    e.preventDefault();
    captureEvent('at_mention');
  }

  updatePosition = () => {
    const offset = this.aCInstance.positionHandler.offset();
    this.aCInstance.dropdown.style.top = `${offset.top}px`;
    this.aCInstance.dropdown.style.left = `${offset.left}px`;
  }

  addParentScrollListener = (el) => {
    let parent = el.parentNode;

    while (parent) {
      if (parent.scrollHeight > parent.clientHeight) {
        parent.addEventListener('scroll', this.updatePosition);
      }
      parent = parent.parentNode;
    }
  }

  removeParentScrollListener = (el) => {
    let parent = el.parentNode;

    while (parent) {
      // Consider the element only if it is scrollable
      if (parent.scrollHeight > parent.clientHeight) {
        // Listen for the scroll event
        parent.removeEventListener('scroll', this.updatePosition);
      }
      parent = parent.parentNode;
    }
  }
}
