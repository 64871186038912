import React, { memo } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEqual from 'lodash/isEqual';

import ErrorMessage from '~/components/forms/ErrorMessage';
import CampaignUseCaseSelect from '~/components/forms/campaign_use_case_select';
import { MessagingServicePhoneNumbersSelect } from '~/components/forms/messaging_service_phone_numbers_select';

const TWILIO_A2P_PHONES_MAX_SIZE = process.env.TWILIO_A2P_PHONES_MAX_SIZE || 400;

const MessagingServiceItem = memo(({
  messaging_service,
  index,
  counter,
  errors,
  isDeleting,
  disableStatus,
  onUseCaseChange,
  onInputChange,
  onCheckboxChange,
  onPhoneNumbersChange,
  onDelete,
  renderCampaignStatus,
}) => (
  <div className="col-12 my-4 p-0">
    <h5 className="mb-2">
      Texting Use Case
      {' '}
      {counter}
      {' '}
      {renderCampaignStatus(messaging_service)}
    </h5>

    <div className="form-group">
      <label htmlFor={`use-case-select-${index}`}>
        Texting Use Case Type
      </label>
      <CampaignUseCaseSelect
        value={messaging_service.usecase}
        onChange={(opt) => onUseCaseChange(opt, messaging_service)}
        id={`use-case-select-${index}`}
        placeholder="Select an option"
        clearable
        className={classNames('d-block', {
          'has-error': errors[index]
            ? errors[index].usecase
            : '',
        })}
        disabled={isDeleting || disableStatus}
      />
      {errors[index] && errors[index].usecase && (
        <ErrorMessage message={errors[index].usecase} />
      )}
    </div>

    <div className="form-group">
      <label htmlFor={`use-case-description-${index}`}>
        Use Case Description
      </label>
      <textarea
        value={messaging_service.description}
        onChange={(e) => onInputChange(e, messaging_service, 'description')}
        id={`use-case-description-${index}`}
        placeholder="Enter the use case details for which you'll send the messages"
        rows="5"
        className={classNames('form-control', {
          'has-error': errors[index]
            ? errors[index].description
            : '',
        })}
        disabled={isDeleting || disableStatus}
      />
      {errors[index] && errors[index].description && (
        <ErrorMessage message={errors[index].description} />
      )}
    </div>

    <div className="form-group">
      <label htmlFor={`message-sample-one-${index}`}>
        Sample Text Message #1
      </label>
      <textarea
        value={messaging_service.message_sample_one}
        onChange={(e) => onInputChange(e, messaging_service, 'message_sample_one')}
        id={`message-sample-one-${index}`}
        placeholder="Enter the message sample content"
        rows="5"
        className={classNames('form-control', {
          'has-error': errors[index]
            ? errors[index].message_sample_one
            : '',
        })}
        disabled={isDeleting || disableStatus}
      />
      {errors[index] && errors[index].message_sample_one && (
        <ErrorMessage
          message={errors[index].message_sample_one}
        />
      )}
    </div>

    <div className="form-group">
      <label htmlFor={`message-sample-two-${index}`}>
        Sample Text Message #2
      </label>
      <textarea
        value={messaging_service.message_sample_two}
        onChange={(e) => onInputChange(e, messaging_service, 'message_sample_two')}
        id={`message-sample-two-${index}`}
        placeholder="Enter the message sample content"
        rows="5"
        className={classNames('form-control', {
          'has-error': errors[index]
            ? errors[index].message_sample_two
            : '',
        })}
        disabled={isDeleting || disableStatus}
      />
      {errors[index] && errors[index].message_sample_two && (
        <ErrorMessage
          message={errors[index].message_sample_two}
        />
      )}
    </div>

    <div className="form-group">
      <label htmlFor={`message-flow-${index}`}>
        Message Flow
      </label>
      <textarea
        value={messaging_service.message_flow}
        onChange={(e) => onInputChange(e, messaging_service, 'message_flow')}
        id={`message-flow-${index}`}
        placeholder="Enter the details regarding how the customers will opt-in"
        rows="5"
        className={classNames('form-control', {
          'has-error': errors[index]
            ? errors[index].message_flow
            : '',
        })}
        disabled={isDeleting || disableStatus}
      />
      {errors[index] && errors[index].message_flow && (
        <ErrorMessage
          message={errors[index].message_flow}
        />
      )}
    </div>

    <div className="form-group">
      <p className="mb-3">Message Contents</p>
      <ul className="list-unstyled d-flex flex-wrap">
        <li className="col-lg-6 col-12 mb-2 pl0">
          <div className="custom-control custom-checkbox">
            <input
              key={`embedded-links-${index}`}
              type="checkbox"
              className="custom-control-input"
              id={`embedded-links-${index}`}
              checked={messaging_service.has_embedded_links}
              onChange={(e) => onCheckboxChange(e, messaging_service, 'has_embedded_links')}
              disabled={isDeleting || disableStatus}
            />
            <label
              className="custom-control-label"
              htmlFor={`embedded-links-${index}`}
            >
              Message will include embedded links.
            </label>
          </div>
        </li>
        <li className="col-lg-6 col-12 mb-2">
          <div className="custom-control custom-checkbox">
            <input
              key={`embedded-phone-${index}`}
              type="checkbox"
              className="custom-control-input"
              id={`embedded-phone-${index}`}
              checked={messaging_service.has_embedded_phone}
              onChange={(e) => onCheckboxChange(e, messaging_service, 'has_embedded_phone')}
              disabled={isDeleting || disableStatus}
            />
            <label
              className="custom-control-label"
              htmlFor={`embedded-phone-${index}`}
            >
              Message will include phone numbers.
            </label>
          </div>
        </li>
      </ul>
    </div>

    <div className="form-group">
      <label
        htmlFor={`phone-numbers-${index}`}
        className="d-block"
      >
        Numbers Available For Association
        <br />
        <small className="text-muted">
          * Max
          {' '}
          {TWILIO_A2P_PHONES_MAX_SIZE}
          {' '}
          phone numbers
        </small>
      </label>
      <MessagingServicePhoneNumbersSelect
        id={`phone-numbers-${index}`}
        onChange={(opt) => onPhoneNumbersChange(opt, messaging_service)}
        value={messaging_service.sms_numbers}
        isInvalid={errors[index] && errors[index].sms_numbers}
      />
      {errors[index] && errors[index].sms_numbers && (
        <ErrorMessage message={errors[index].sms_numbers} />
      )}
    </div>

    <div className="text-right">
      <button
        type="button"
        className="btn btn-danger"
        onClick={(e) => onDelete(e, messaging_service)}
        disabled={isDeleting}
      >
        {isDeleting ? (
          <>
            <FontAwesomeIcon icon="far fa-spinner" pulse className="mr-2" />
            Deleting...
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={['far', 'fa-trash-alt']} />
            {' '}
            Delete
          </>
        )}
      </button>
    </div>
    <hr />
  </div>
), (prevProps, nextProps) => (
  prevProps.messaging_service.id === nextProps.messaging_service.id
    && prevProps.messaging_service.usecase === nextProps.messaging_service.usecase
    && prevProps.messaging_service.description === nextProps.messaging_service.description
    && prevProps.messaging_service.message_sample_one === nextProps.messaging_service.message_sample_one
    && prevProps.messaging_service.message_sample_two === nextProps.messaging_service.message_sample_two
    && prevProps.messaging_service.message_flow === nextProps.messaging_service.message_flow
    && prevProps.messaging_service.has_embedded_links === nextProps.messaging_service.has_embedded_links
    && prevProps.messaging_service.has_embedded_phone === nextProps.messaging_service.has_embedded_phone
    && isEqual(prevProps.messaging_service.sms_numbers, nextProps.messaging_service.sms_numbers)
    && prevProps.isDeleting === nextProps.isDeleting
    && prevProps.disableStatus === nextProps.disableStatus
    && isEqual(prevProps.errors, nextProps.errors)
));

MessagingServiceItem.defaultProps = {
  errors: {},
};

MessagingServiceItem.propTypes = {
  errors:               PropTypes.shape({}),
  messaging_service:    PropTypes.shape({}).isRequired,
  index:                PropTypes.number.isRequired,
  counter:              PropTypes.number.isRequired,
  isDeleting:           PropTypes.bool.isRequired,
  disableStatus:        PropTypes.bool.isRequired,
  onUseCaseChange:      PropTypes.func.isRequired,
  onInputChange:        PropTypes.func.isRequired,
  onCheckboxChange:     PropTypes.func.isRequired,
  onPhoneNumbersChange: PropTypes.func.isRequired,
  onDelete:             PropTypes.func.isRequired,
  renderCampaignStatus: PropTypes.func.isRequired,
};

export default MessagingServiceItem;
