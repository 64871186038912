import classNames          from 'classnames';
import autoBind            from 'react-autobind';
import React               from 'react';
import PropTypes           from 'prop-types';
import { Input, Collapse } from 'reactstrap';
import Geosuggest          from 'react-geosuggest';
import isEmail             from 'validator/lib/isEmail';
import isMobilePhone       from 'validator/lib/isMobilePhone';
import PhoneInput          from 'react-phone-input-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import brokerbit                     from '~/lib/brokerbit';
import DuplicateContactCard          from '~/components/duplicate_contact_card';
import ErrorMessageWithIcon          from '~/components/forms/ErrorMessageWithIcon';
import Select                        from '~/components/forms/select';
import LeadHelpers                   from '~/helpers/lead_helpers';
import LeadDrawerActions             from '~/actions/lead_drawer_actions';
import LeadDrawerStore               from '~/stores/lead_drawer_store';
import MultipleListingServicesSelect from '~/components/forms/multiple_listing_services_select';
import AllowOrgDuplicateCheckBox     from '~/components/forms/allow_org_duplicate_checkbox';
import GlobalContainer               from '~/components/global_container';
import DateTimePicker                from '~/components/forms/DateTimePicker';
import Tooltipable                   from '~/components/effects/tooltipable';

let leadStoreListener;
let approvedForm = false;

const { currentTeam } = Rails.helpers;
const teamID = currentTeam ? currentTeam.id : null;
const defaultEmailTypeOptions = [
  { value: 'office',   label: 'Office' },
  { value: 'personal', label: 'Personal' },
  { value: 'other',    label: 'Other' },
];
const OPT_IN_TOOLTIP = 'This contact has opted in to receive communications when completing a form signup in a Brokerkit or other landing page.';

class LeadDrawerMainDetails extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);

    const isOpen = localStorage.isDetailsOpen ? JSON.parse(localStorage.isDetailsOpen) : true;

    this.emptyEmail = {
      id:         null,
      email:      '',
      email_type: 'office',
      main:       false,
      team_id:    teamID,
      _destroy:   false,
    };

    this.emptyAgentID = {
      id:                            null,
      agent_id:                      '',
      multiple_listing_service_ouid: '',
      _destroy:                      false,
    };

    this.state = {
      opened: this.props.opened,
      fields: _lodash.pick(props.lead, 'first_name', 'last_name', 'email', 'phone', 'birthday',
        'alt_address', 'alt_city', 'alt_zip', 'alt_lat', 'alt_lng', 'other_phone',
        'license_number', 'license_exp_date', 'lead_emails_attributes', 'lead_agent_ids_attributes', 'allow_duplicates_in_org'),
      duplicates_found_in_org: false,
      duplicate_org_accounts:  [],
      errors:                  {},
      warnings:                {},
      isOpen,
    };
  }

  componentDidMount() {
    const { fields } = this.state;

    // listen to LeadDrawerStore changes
    leadStoreListener = LeadDrawerStore.addListener(this.onChange);

    if (_lodash.size(fields.lead_emails_attributes) === 0) {
      this.setState({
        fields: {
          ...fields,
          lead_emails_attributes: [{
            id:         null,
            email:      '',
            email_type: 'office',
            main:       true,
            team_id:    teamID,
            _destroy:   false,
          }],
        },
      });
    }

    if (_lodash.size(fields.lead_agent_ids_attributes) === 0) {
      this.setState({
        fields: {
          ...fields,
          lead_agent_ids_attributes: [{
            id:                            null,
            agent_id:                      '',
            multiple_listing_service_ouid: '',
            _destroy:                      false,
          }],
        },
      });
    }
  }

  componentWillUnmount() {
    // remove listener to LeadDrawerStore changes on Unmount
    if (leadStoreListener) leadStoreListener.remove();
  }

  handleAddressChange(address) {
    const { fields } = this.state;

    this.setState({
      fields: {
        ...fields,
        alt_address: address,
      },
    });
  }

  handleAddressSuggestion(suggest) {
    const { fields } = this.state;
    if (!suggest) return;

    const addressComponents = suggest.gmaps.address_components;
    const readableAddress = LeadHelpers.parseAddressComponents(addressComponents);

    this.setState({
      fields: {
        ...fields,
        alt_address: suggest.label,
        alt_city:    readableAddress.locality,
        alt_zip:     readableAddress.postal_code,
        alt_lat:     suggest.location.lat,
        alt_lng:     suggest.location.lng,
      },
    });
  }

  handleEmailChange(e, leadEmail) {
    e.preventDefault();

    leadEmail.email = e.target.value;

    this.setState({ lead: this.state.lead });
  }

  handleEmailTypeChange(opt, leadEmail) {
    leadEmail.email_type = opt ? opt.value : '';

    this.setState({ lead: this.state.lead });
  }

  handleMainChange(e, leadEmail) {
    leadEmail.main = e.target.checked;

    this.setState({ lead: this.state.lead });
  }

  handleLeadEmailDelete(e, leadEmail) {
    e.preventDefault();

    brokerbit.confirmBox({
      message:  'Are you sure you want to delete this email?',
      callback: (ok) => {
        if (ok) {
          leadEmail._destroy = true;
          this.setState({ fields: this.state.fields });
        }
      },
    });
  }

  handleAgentIdChange(e, leadAgentId) {
    e.preventDefault();

    leadAgentId.agent_id = e.target.value;

    this.setState({ lead: this.state.lead });
  }

  handleMultipleListingServiceChange(value, leadAgentId) {
    leadAgentId.multiple_listing_service_ouid = value;

    this.setState({ lead: this.state.lead });
  }

  handleLeadAgentIdDelete(e, leadAgentId) {
    e.preventDefault();

    brokerbit.confirmBox({
      message:  'Are you sure you want to delete this Agent ID?',
      callback: (ok) => {
        if (ok) {
          leadAgentId._destroy = true;
          this.setState({ fields: this.state.fields });
        }
      },
    });
  }

  // when LeadDrawerStore changes...
  onChange() {
    let duplicates_found_in_org = false;

    const leadDrawerState = LeadDrawerStore.getState();
    const {
      leadDrawerStoreAction, errors, lead, warnings,
    } = leadDrawerState;
    const { duplicate_org_accounts } = this.state;

    const emailErrors = {};
    const agentIDErrors = {};

    if (leadDrawerStoreAction === 'updateLeadDone') {
      this.setState({
        opened:   false,
        errors:   {},
        warnings: {},
        fields:   _lodash.pick(lead, 'first_name', 'last_name', 'email', 'phone', 'birthday',
          'alt_address', 'alt_city', 'alt_zip', 'alt_lat', 'alt_lng', 'lead_agent_ids_attributes',
          'license_number', 'license_exp_date', 'other_phone', 'lead_emails_attributes'),
      });
    }

    if (leadDrawerStoreAction === 'updateLeadFail') {
      if (errors.duplicate_emails) {
        errors.duplicate_emails.forEach((e, i) => {
          if (e) {
            if (e.context === 'org') {
              duplicate_org_accounts.push(`${e.match.name}: Email: ${e.value}`);
              duplicates_found_in_org = true;
            }

            emailErrors[i] = {
              duplicate: 'it looks like a contact with this email already exists.',
              context:   e.context,
              match:     e.match,
            };
          }
        });
      }

      if (errors.duplicate_agent_ids) {
        errors.duplicate_agent_ids.forEach((e, i) => {
          if (e) {
            if (e.context === 'org') {
              duplicate_org_accounts.push(`${e.match.name}: Agent ID/MLS: ${e.value}`);
              duplicates_found_in_org = true;
            }

            agentIDErrors[i] = {
              duplicate: 'It looks like this agent id already exists.',
              context:   e.context,
              match:     e.match,
            };
          }
        });
      }

      this.setState({
        errors:     emailErrors,
        warnings:   agentIDErrors,
        submitting: false,
        duplicates_found_in_org,
      });
    }
  }

  onEditClick(e)  {
    e.preventDefault();

    this.setState({
      opened: true,
    });
  }

  onCollapseClick(e) {
    e.preventDefault();

    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });
    localStorage.isDetailsOpen = !isOpen;
  }

  onEditCloseClick(e) {
    e.preventDefault();

    approvedForm = false;

    this.setState({
      opened:   false,
      warnings: {},
    });
  }

  onFormSubmit(e) {
    e.preventDefault();

    const { fields } = this.state;
    const { lead_agent_ids_attributes, lead_emails_attributes } = fields;
    const { lead, query }       = this.props;
    const leadEmailAttributes = lead_emails_attributes.filter((x, i) => !x._destroy);
    const leadAgentIdAttributes = lead_agent_ids_attributes.filter((x, i) => !x._destroy);

    const errors = this.validate();

    this.setState({ duplicate_org_accounts: [], duplicates_found_in_org: false });

    if (_lodash.size(errors) === 0 && !approvedForm) {
      leadEmailAttributes.forEach((leadEmail) => {
        if (!leadEmail.email) {
          leadEmail._destroy = true;
        }
      });

      leadAgentIdAttributes.forEach((leadAgentId) => {
        if (!leadAgentId.agent_id) {
          leadAgentId.multiple_listing_service_ouid = '';
        }

        if (!leadAgentId.agent_id && !leadAgentId.multiple_listing_service_ouid) {
          leadAgentId._destroy = true;
        }
      });

      LeadDrawerActions.updateLead({ ...fields, id: lead.id }, query);
    } else {
      this.setState({ errors });
    }
  }

  setFormField(name, val) {
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields, [name]: val,
      },
    });
  }

  _handleCellPhoneChange = (phone_number) => {
    this.setFormField('phone', phone_number);
  };

  _handleOtherPhoneChange = (phone_number) => {
    this.setFormField('other_phone', phone_number);
  };

  validate() {
    let all_emails_blank = true;
    let all_agent_ids_blank = true;

    const { fields } = this.state;
    const {
      first_name, last_name, phone, other_phone, lead_emails_attributes, lead_agent_ids_attributes,
    } = fields;

    const errors = {};
    const warnings = {};

    const leadEmailAttributes   = lead_emails_attributes.filter((x, i) => !x._destroy);
    const leadAgentIdAttributes = lead_agent_ids_attributes.filter((x, i) => !x._destroy);
    const checkedCount = leadEmailAttributes.filter((x, i) => x.main).length;

    if (!first_name) {
      errors.first_name = 'This field can not be empty';
    }

    if (!last_name) {
      errors.last_name = 'This field can not be empty';
    }

    if (!_lodash.isEmpty(phone) && !isMobilePhone(phone, ['en-US'])) {
      errors.phone = 'Is invalid';
    }

    if (!_lodash.isEmpty(other_phone) && !isMobilePhone(other_phone, ['en-US'])) {
      errors.other_phone = 'Is invalid';
    }

    if (leadEmailAttributes.length > 0 && checkedCount < 1) {
      errors.emails = ['Please select any main email'];
    }

    leadEmailAttributes.forEach((leadEmail, index) => {
      if (leadEmail.email) {
        all_emails_blank = false;

        if (!isEmail(leadEmail.email)) {
          errors[index] = { email: 'The provided email is invalid' };
        }
      }

      if (!leadEmail.email_type) {
        errors[index] = { email_type: "Can't be empty" };
      }
    });

    if (GlobalContainer.product() === 'retention') {
      if (leadEmailAttributes.length === 0 || all_emails_blank) {
        errors.no_email = ['Please add an email address to create this record so that there is a way to match and update the data later and this email will function as their username'];
      }
    }

    leadAgentIdAttributes.forEach((leadAgentId, index) => {
      if (leadAgentId.agent_id) {
        all_agent_ids_blank = false;
      }

      if (!!leadAgentId.agent_id && !leadAgentId.multiple_listing_service_ouid) {
        warnings[index] = { multiple_listing_service_ouid: 'It is recommended that you select the MLS for this agent ID.' };
      }
    });

    if (GlobalContainer.product() === 'recruiting') {
      if ((leadEmailAttributes.length === 0 && leadAgentIdAttributes.length === 0) || (all_emails_blank && all_agent_ids_blank)) {
        errors.no_email_agent_id = ['Please add either an email address or agent ID to be able to create this record so that there is a way to match and update the data later.'];
      }
    }

    if (_lodash.size(errors) === 0 && _lodash.size(warnings) !== 0) {
      approvedForm = !approvedForm;
    } else {
      approvedForm = false;
    }

    this.setState({ warnings });

    return errors;
  }

  addLeadEmail() {
    const { fields } = this.state;

    fields.lead_emails_attributes.push({ ...this.emptyEmail });

    this.setState({ fields });
  }

  isLeadEmailValid(index) {
    const { errors } = this.state;

    if (errors[index] || errors.no_email_agent_id || errors.no_email) {
      return errors.no_email_agent_id || errors.no_email || errors[index].email || errors[index].duplicate;
    }

    return '';
  }

  isLeadAgentIDValid(index) {
    const { errors, warnings } = this.state;

    if (warnings[index] || errors.no_email_agent_id) {
      return errors.no_email_agent_id || warnings[index].duplicate;
    }

    return '';
  }

  addLeadAgentId() {
    const { fields } = this.state;

    fields.lead_agent_ids_attributes.push({ ...this.emptyAgentID });

    this.setState({ fields });
  }

  renderLeadEmail() {
    const { errors, fields } = this.state;
    const { lead_emails_attributes  } = fields;

    const leadEmailAttributes = lead_emails_attributes.filter((x, i) => !x._destroy);
    const checkedCount = leadEmailAttributes.filter((x, i) => x.main).length;
    const disableMainCheckbox = checkedCount >= 1;

    return leadEmailAttributes.map((leadEmail, index) => {
      const emailDOM = (
        <div className="form-row align-items-center mb-2" key={index}>
          <div className="col-4 col-lg-5">
            <Input
              className={classNames({ 'has-error':  this.isLeadEmailValid(index) })}
              value={leadEmail.email}
              onChange={(e) => this.handleEmailChange(e, leadEmail)}
            />
          </div>

          <div className="col-4 col-lg-4 lead-emails">
            <Select
              value={leadEmail.email_type}
              options={defaultEmailTypeOptions}
              onChange={(opt) => this.handleEmailTypeChange(opt, leadEmail)}
              placeholder="--- Select ---"
              className={classNames({ 'has-error': errors[index] ? errors[index].email_type : '' })}
            />
          </div>

          <div className="col-auto">
            <div className="form-check">
              <input
                key={index}
                className="form-check-input"
                type="checkbox"
                id={`main_${index}`}
                onChange={(e) => this.handleMainChange(e, leadEmail)}
                checked={leadEmail.main || false}
                disabled={!leadEmail.main && disableMainCheckbox}
              />
              <label
                className="form-check-label"
                htmlFor={`main_${index}`}
              >
                Main
              </label>
            </div>
          </div>

          <div className="col-auto">
            <button type="button" className="btn btn-outline-danger btn-sm" onClick={(e) => this.handleLeadEmailDelete(e, leadEmail)}>
              <FontAwesomeIcon icon={['fas', 'fa-times']} />
            </button>
          </div>
          {errors[index] && errors[index].duplicate && (
            <div className="col-12">
              <ErrorMessageWithIcon message={[errors[index].duplicate]} />
              { errors[index].context === 'team' && <DuplicateContactCard duplicate={errors[index]} /> }
            </div>
          )}

          { errors[index] && errors[index].email && (
            <div className="col-12">
              <ErrorMessageWithIcon message={errors[index].email} />
            </div>
          )}
        </div>
      );
      return emailDOM;
    });
  }

  renderLeadAgentId() {
    const { errors, warnings, fields } = this.state;
    const { lead_agent_ids_attributes  } = fields;
    const leadAgentIdAttributes = lead_agent_ids_attributes.filter((x, i) => !x._destroy);

    return leadAgentIdAttributes.map((leadAgentId, index) => {
      const agentIdDOM = (
        <div className="form-row align-items-center mb-2" key={index}>
          <div className="col-5 col-lg-5">
            <Input
              className={classNames({ 'has-error': this.isLeadAgentIDValid(index) })}
              value={leadAgentId.agent_id}
              onChange={(e) => this.handleAgentIdChange(e, leadAgentId)}
            />
          </div>

          <div className="col-5 col-lg-6 lead-agent-ids" id={`mls-select-${index}`}>
            <MultipleListingServicesSelect
              value={leadAgentId.multiple_listing_service_ouid}
              placeholder="--- Select a MLS ---"
              onChange={(val) => this.handleMultipleListingServiceChange(val, leadAgentId)}
              teamMlses
              primaryMls
              clearable
              target={`mls-select-${index}`}
            />
          </div>

          <div className="col-auto">
            <button type="button" className="btn btn-outline-danger btn-sm" onClick={(e) => this.handleLeadAgentIdDelete(e, leadAgentId)}>
              <FontAwesomeIcon icon={['fas', 'fa-times']} />
            </button>
          </div>

          {warnings[index] && warnings[index].multiple_listing_service_ouid && (
            <div className="col-12 text-warning mt-2">
              <FontAwesomeIcon icon={['fas', 'fa-exclamation-triangle']} />
              {' '}
              {warnings[index].multiple_listing_service_ouid}
            </div>
          )}

          { warnings[index] && warnings[index].duplicate && (
            <div className="col-12">
              <ErrorMessageWithIcon message={[warnings[index].duplicate]} />
              {warnings[index].context === 'team' && <DuplicateContactCard duplicate={warnings[index]} />}
            </div>
          )}
        </div>
      );

      return agentIdDOM;
    });
  }

  renderContent() {
    const { isOpen } = this.state;
    const { lead }   = this.props;

    return (
      <div>
        <div className="pull-right">
          <button type="button" className="btn btn-secondary btn-sm mr-1" onClick={this.onCollapseClick}>
            { isOpen ? (
              <>
                <FontAwesomeIcon icon={['far', 'fa-minus']} />
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={['far', 'fa-plus']} />
              </>
            )}
          </button>
          { Rails.abilities.manageLead && (
            <button type="button" className="btn btn-secondary btn-sm" onClick={this.onEditClick}>
              <FontAwesomeIcon icon={['far', 'fa-edit']} />
              {' '}
              Edit
            </button>
          )}
        </div>

        <h4 className="mb15">Personal Details</h4>

        <Collapse isOpen={isOpen}>
          <table className="table table-flush table-sm no-border">
            <tbody>
              <tr>
                <th width="40%" className="text-grey-dark">Full Name:</th>
                <td width="60%" className="text-black">{LeadHelpers.renderLeadName(lead)}</td>
              </tr>

              {lead.lead_emails_attributes.map((leadEmail, index) => {
                if (!leadEmail.id) return null;

                const mainEmail = leadEmail.main ? '(Main)' : '';

                return (
                  <tr key={index}>
                    <th width="40%" className="text-grey-dark">
                      {_lodash.titleize(leadEmail.email_type)}
                      {' '}
                      Email:
                      {' '}
                      {mainEmail}
                    </th>
                    <td width="60%" className="text-black">{leadEmail.email}</td>
                  </tr>
                );
              })}

              <tr>
                <th width="40%" className="text-grey-dark">Cell Phone:</th>
                <td width="60%">
                  <a href={`tel:${lead.phone}`}>{LeadHelpers.renderPhoneNumber(lead.phone)}</a>
                </td>
              </tr>
              <tr>
                <th width="40%" className="text-grey-dark">Other Phone:</th>
                <td width="60%">
                  <a href={`tel:${lead.other_phone}`}>{LeadHelpers.renderPhoneNumber(lead.other_phone)}</a>
                </td>
              </tr>

              {lead.lead_agent_ids_attributes.map((leadAgentId, index) => {
                if (!leadAgentId.id) return null;

                return (
                  <tr key={index}>
                    <th width="40%" className="text-grey-dark">Agent ID:</th>
                    <td width="60%" className="text-black">
                      {leadAgentId.agent_id}
                      {leadAgentId.mls_name && ` - ${leadAgentId.mls_name}`}
                    </td>
                  </tr>
                );
              })}

              <tr>
                <th width="40%" className="text-grey-dark">License Number:</th>
                <td width="60%" className="text-black">{lead.license_number}</td>
              </tr>
              <tr>
                <th width="40%" className="text-grey-dark">License Expiration Date:</th>
                <td width="60%" className="text-black">{lead.license_exp_date && Moment(lead.license_exp_date).format('LL')}</td>
              </tr>
              <tr>
                <th width="40%" className="text-grey-dark">Birthday:</th>
                <td width="60%" className="text-black">{lead.birthday && Moment(lead.birthday).format('MMMM D')}</td>
              </tr>
              <tr>
                <th width="40%" className="text-grey-dark">Home Address:</th>
                <td width="60%" className="text-black">
                  { lead.alt_address && (
                    <div style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
                      <span className="mr5">{lead.alt_address}</span>
                      <a href={`https://www.google.com/maps/place/${lead.alt_address}`} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={['far', 'fa-external-link-alt']} />
                      </a>
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <th width="40%" className="text-grey-dark">Opted In:</th>
                <td width="60%" className="text-black">
                  <div className="form-check">
                    <input
                      id="opt_in"
                      className="form-check-input"
                      type="checkbox"
                      checked={lead.opt_in ?? false}
                      onChange={(e) => e.preventDefault()}
                    />
                    <Tooltipable text={OPT_IN_TOOLTIP} trigger="click">
                      <FontAwesomeIcon
                        icon={['far', 'fa-exclamation-circle']}
                        className="text-grey"
                        size="xs"
                      />
                    </Tooltipable>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Collapse>
      </div>
    );
  }

  renderForm() {
    const { lead } = this.props;
    const {
      fields, errors, duplicates_found_in_org, duplicate_org_accounts,
    } = this.state;
    const saveButton = approvedForm ? 'Approve & Save' : 'Save';

    return (
      <div>
        <div className="text-right">
          <button type="button" className="btn btn-secondary btn-sm" onClick={this.onEditCloseClick}>
            <FontAwesomeIcon icon={['fas', 'fa-times']} />
            {' '}
            Close
          </button>
        </div>

        <form className="edit-lead-form" onSubmit={this.onFormSubmit}>
          <div className="form-group string optional lead_first_name mb15">
            <label htmlFor="lead_first_name" className="form-control-label string optional">First name</label>
            <input
              id="lead_first_name"
              name="first_name"
              type="text"
              className={classNames('form-control', 'string', { 'has-error': !!errors.first_name })}
              value={fields.first_name}
              onChange={(val) => this.setFormField('first_name', val.target.value)}
            />
            { errors.first_name && <ErrorMessageWithIcon message={[errors.first_name]} /> }
          </div>

          <div className="form-group string optional lead_last_name mb15">
            <label htmlFor="lead_last_name" className="form-control-label string">Last name</label>
            <input
              id="lead_last_name"
              name="last_name"
              type="text"
              className={classNames('form-control', 'string', { 'has-error': !!errors.last_name })}
              value={fields.last_name}
              onChange={(val) => this.setFormField('last_name', val.target.value)}
            />
            { errors.last_name && <ErrorMessageWithIcon message={[errors.last_name]} /> }
          </div>

          <div className="field-group mb15">
            <label htmlFor="btn-lead-email" className="d-block">
              <a
                href="#lead_email"
                id="btn-lead-email"
                className="pull-right"
                onClick={(e) => {
                  e.preventDefault();
                  this.addLeadEmail();
                }}
              >
                Add Email
              </a>
              Email
            </label>

            {this.renderLeadEmail()}

            {errors.emails && (<ErrorMessageWithIcon message={errors.emails} />)}
            {errors.no_email && (<ErrorMessageWithIcon message={errors.no_email} />)}
            {errors.no_email_agent_id && (<ErrorMessageWithIcon message={errors.no_email_agent_id} />)}

            { duplicates_found_in_org && (
              <AllowOrgDuplicateCheckBox
                checked={fields.allow_duplicates_in_org}
                duplicate_org_accounts={duplicate_org_accounts}
                onChange={(checked) => this.setFormField('allow_duplicates_in_org', checked)}
              />
            )}

          </div>

          <div className="form-group string optional lead_phone mb15">
            <label htmlFor="lead_phone" className="form-control-label string optional">Cell Phone</label>
            <PhoneInput
              country="us"
              onlyCountries={['us']}
              disableCountryCode
              disableDropdown
              placeholder=""
              defaultMask="(...) ...-...."
              value={LeadHelpers.nationalFormatPhoneNumber(fields.phone)}
              onChange={this._handleCellPhoneChange}
              specialLabel={false}
              inputProps={{
                id:        'lead_phone',
                name:      'phone',
                className: classNames('form-control string phone optional', { 'has-error': !!errors.phone }),
              }}
            />
          </div>

          <div className="form-group string optional lead_other_phone mb15">
            <label htmlFor="lead_other_phone" className="form-control-label string optional">Other Phone</label>
            <PhoneInput
              country="us"
              onlyCountries={['us']}
              disableCountryCode
              disableDropdown
              placeholder=""
              defaultMask="(...) ...-...."
              value={LeadHelpers.nationalFormatPhoneNumber(fields.other_phone)}
              onChange={this._handleOtherPhoneChange}
              specialLabel={false}
              inputProps={{
                id:        'lead_other_phone',
                name:      'other_phone',
                className: classNames('form-control string other_phone optional', { 'has-error': !!errors.other_phone }),
              }}
            />
          </div>

          <div className="field-group mb15">
            <label htmlFor="btn-lead-agent-id" className="d-block">
              <a
                href="#lead_agent_id"
                id="btn-lead-agent-id"
                className="pull-right"
                onClick={(e) => {
                  e.preventDefault();
                  this.addLeadAgentId();
                }}
              >
                Add Agent ID
              </a>
              Agent ID
            </label>

            {this.renderLeadAgentId()}
          </div>

          <div className="form-group string optional lead_license_number mb15">
            <label htmlFor="lead_license_number" className="form-control-label string optional">License Number</label>
            <input
              id="lead_license_number"
              name="license_number"
              type="text"
              className="form-control string optional"
              value={fields.license_number}
              onChange={(val) => this.setFormField('license_number', val.target.value)}
            />
          </div>

          <div className="form-group string optional lead_license_exp_date mb15">
            <label htmlFor="lead_license_exp_date" className="form-control-label string optional">License Expiration Date</label>
            <DateTimePicker
              id="lead_license_exp_date"
              placeholder="Select License Expiration Date"
              format="LL"
              pickerType="date"
              showClear
              value={Moment(fields.license_exp_date)?.format('LL')}
              onChange={(val) => this.setFormField('license_exp_date', val && val?.format('YYYY-MM-DD'))}
            />
          </div>

          <div className="form-group string optional lead_birthday mb15">
            <label htmlFor="lead_birthday" className="form-control-label string optional">Birthday</label>
            <DateTimePicker
              id="lead_birthday"
              placeholder="Select Birthday Month and Day"
              format="MMMM D"
              pickerType="date"
              showClear
              value={Moment(fields.birthday)?.format('MMMM D')}
              onChange={(val) => this.setFormField('birthday', val && val?.format('YYYY-MM-DD'))}
            />
          </div>

          <div className="form-group string optional lead_address mb15">
            <label htmlFor="lead_address" className="form-control-label string optional">Home Address</label>
            <Geosuggest
              country={['us', 'ca']}
              inputClassName="form-control"
              suggestsClassName={classNames('card p0 mt5', `b-${GlobalContainer.productColor()}`)}
              suggestsHiddenClassName="d-none"
              suggestItemClassName="p10"
              id="lead_home_address"
              ref={(input) => this.leadHomeAddressInput = input}
              name="home_address"
              placeholder="e.g. P.O. Box 6752 Denver, CO 80206"
              initialValue={lead.alt_address}
              onChange={this.handleAddressChange}
              onSuggestSelect={this.handleAddressSuggestion}
            />
          </div>

          <div className="form-group mb15">
            <button type="submit" className="btn btn-success">{saveButton}</button>
          </div>
        </form>
      </div>
    );
  }

  render() {
    const { opened } = this.state;

    if (opened) {
      return this.renderForm();
    }

    return this.renderContent();
  }
}

LeadDrawerMainDetails.defaultProps = {
  lead:   null,
  query:  null,
  opened: false,
};

LeadDrawerMainDetails.propTypes = {
  lead:   PropTypes.shape({}),
  query:  PropTypes.shape({}),
  opened: PropTypes.bool,
};

export default LeadDrawerMainDetails;
