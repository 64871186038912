import React, {
  useState, useCallback,
} from 'react';
import { PropTypes }  from 'prop-types';
import { useHistory } from 'react-router-dom';
import isEmpty        from 'lodash/isEmpty';

import brokerbit           from '~/lib/brokerbit';
import GlobalContainer     from '~/components/global_container';
import DonateCampaignModal from '~/components/modals/DonateCampaignModal';
import CampaignItem        from './CampaignItem';

const CampaignsTable = ({ campaigns, deleteCampaign }) => {
  const history = useHistory();
  const [showDonateModal, setShowDonateModal] = useState(false);
  const [donationCampaignId, setDonationCampaignId] = useState(null);

  const handleCampaignClick = useCallback((campaign) => {
    const CampaignEditUri = GlobalContainer.productUri(`/campaigns/${campaign.id}/edit`);
    history.push(CampaignEditUri);
  }, [history]);

  const handleCampaignDelete = useCallback((campaign) => {
    if (isEmpty(campaign)) return;

    brokerbit.confirmBox({
      message:  'Are you sure you want to delete this campaign?',
      callback: (ok) => {
        if (ok) {
          deleteCampaign(campaign);
        }
      },
    });
  }, [deleteCampaign]);

  const handleCampaignDonate = useCallback((campaign) => {
    if (isEmpty(campaign)) return;

    setDonationCampaignId(campaign.id);
    setShowDonateModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowDonateModal(false);
    setDonationCampaignId(null);
  }, []);

  const renderCampaignRows = () => campaigns.map((campaign) => (
    <CampaignItem
      key={`campaign-${campaign.id}`}
      campaign={campaign}
      handleCampaignClick={handleCampaignClick}
      handleCampaignDelete={handleCampaignDelete}
      handleCampaignDonate={handleCampaignDonate}
    />
  ));

  return (
    <>
      <table className="table table-hover table-responsive">
        <tbody>{renderCampaignRows()}</tbody>
      </table>

      {donationCampaignId && (
        <DonateCampaignModal
          campaignId={donationCampaignId}
          show={showDonateModal}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

CampaignsTable.defaultProps = {
  campaigns:      [],
  deleteCampaign: () => false,
};

CampaignsTable.propTypes = {
  campaigns:      PropTypes.arrayOf(PropTypes.shape({})),
  deleteCampaign: PropTypes.func,
};

export default CampaignsTable;
