import React         from 'react';
import { PropTypes } from 'prop-types';
import { Input }     from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  OwnerPicker,
  ReferrerPicker,
} from '~/components/forms/team_member_picker';
import {
  BucketSelect,
  ProductionLabelSelect,
  RatingSelect,
  SourceSelect,
  CompanySelect,
  CitySelect,
  ClosestOfficeSelect,
  ClosestOfficeAddressSelect,
  EngagementSelect,
  EngagementTimePeriodSelect,
  ProductionOfficeZipSelect,
  CurrentSwitchRiskSelect,
  EffectivenessGradeSelect,
  LeadOfficeZipSelect,
} from '~/components/forms/lead_fields';
import LabelSelect              from '~/components/forms/lead_fields/label_select';
import ProductionSelect         from '~/components/forms/lead_fields/production_select';
import ProductionInsightsSelect from  '~/components/forms/lead_fields/production_insights_select';
import ProductionDatesSelect    from '~/components/forms/lead_fields/production_dates_select';
import ProductionLocationSelect from '~/components/forms/lead_fields/production_location_select';
import CampaignPicker           from '~/components/forms/campaign_picker';
import StatusSelect             from '~/components/forms/dropdowns/StatusSelect';
import DateTimePicker           from '~/components/forms/DateTimePicker';
import Tooltipable              from '~/components/effects/tooltipable';
import {
  relitixProductionFilterTooltip,
  relitixProductionInsightsFilterTooltip,
  relitixProductionLocationFilterTooltip,
  relitixProductionDatesFilterTooltip,
}                               from '~/constants/tooltips/RelitixAdvancedSearchTooltips';

const processPickerValue = (value) => {
  if (!value || value.length === 0) return null;

  return value;
};

const RecruitingSearchFilters = ({
  searchData,
  clearSearch,
  hideFilters,
  handleSingleSelectChange,
  handleMultiSelectChange,
  handleDateSelectChange,
  handleCheckBoxClick,
}) => {
  const productionSelectValue                 = processPickerValue(searchData.production_select_value);
  const productionInsightsSelectValue         = processPickerValue(searchData.production_insights_select_value);
  const productionLocationSelectValue         = processPickerValue(searchData.production_location_select_value);
  const productionDatesSelectValue            = processPickerValue(searchData.production_dates_select_value);
  const EngagementSelectValue                 = processPickerValue(searchData.engagement);
  const ownerValue                            = processPickerValue(searchData.owner);
  const referrerValue                         = processPickerValue(searchData.referrer);
  const productionValue                       = processPickerValue(searchData.production);
  const statusesValue                         = processPickerValue(searchData.status);
  const sourcesValue                          = processPickerValue(searchData.source);
  const ratingsValue                          = processPickerValue(searchData.rating);
  const companiesValue                        = processPickerValue(searchData.currently_at);
  const bucketsValue                          = processPickerValue(searchData.bucket);
  const campaignsValue                        = processPickerValue(searchData.campaign);
  const citiesValue                           = processPickerValue(searchData.city);
  const labelsValue                           = processPickerValue(searchData.label);
  const engagementTimePeriodValue             = processPickerValue(searchData.engagement_time_period);
  const productionInsightsListingEffGrade     = processPickerValue(searchData.production_insights_listing_eff_grade);
  const productionInsightsCurrentSwitchRisk   = processPickerValue(searchData.production_insights_current_switch_risk);
  const leadOfficeZip                          = processPickerValue(searchData.lead_office_zip);
  const productionClosestOffice                = processPickerValue(searchData.production_closest_office);
  const productionLocationClosestOfficeAddress = processPickerValue(searchData.production_location_closest_office_address);
  const centerOfProductionZips                = processPickerValue(searchData.center_of_production_zips);
  const productionStartDate                   = processPickerValue(searchData.production_start_date);
  const productionEndDate                     = processPickerValue(searchData.production_end_date);
  const referredAtStart                       = processPickerValue(searchData.referred_at_start);
  const referredAtEnd                         = processPickerValue(searchData.referred_at_end);
  const dueDateAtStart                        = processPickerValue(searchData.due_date_at_start);
  const dueDateAtEnd                          = processPickerValue(searchData.due_date_at_end);
  const lastActivityAtStart                   = processPickerValue(searchData.last_activity_at_start);
  const lastActivityAtEnd                     = processPickerValue(searchData.last_activity_at_end);
  const lastAppointmentAtStart                = processPickerValue(searchData.last_appointment_at_start);
  const lastAppointmentAtEnd                  = processPickerValue(searchData.last_appointment_at_end);
  const birthdayStart                         = processPickerValue(searchData.birthday_start);
  const birthdayEnd                           = processPickerValue(searchData.birthday_end);

  const EngagementClasses = EngagementSelectValue ? 'col-lg-2 col-md-4' : 'col-md-4';

  return (
    <div className="">
      <div className="form-group mb15">
        <a href="#hide-filters" className="" onClick={hideFilters}>Hide advanced options</a>
      </div>

      <div className="row">
        <div className="col-md-4 mb15">
          <label htmlFor="search_owner">Owner</label>
          <OwnerPicker
            id="search_owner"
            value={ownerValue}
            placeholder="Select Owners"
            onChange={(selectedOptions) => {
              handleMultiSelectChange('owner', selectedOptions);
            }}
            multi
            blank
            clearable
          />
        </div>

        <div className="col-md-4 mb15">
          <label htmlFor="search_referrer">Referrer</label>
          <ReferrerPicker
            id="search_referrer"
            value={referrerValue}
            placeholder="Select Referrers"
            onChange={(selectedOptions) => {
              handleMultiSelectChange('referrer', selectedOptions);
            }}
            multi
            blank
            clearable
          />
        </div>

        <div className="col-md-4 mb15">
          <label htmlFor="search_prod_val">Production Value</label>
          <ProductionLabelSelect
            id="search_prod_val"
            value={productionValue}
            placeholder="Select Production Values"
            onChange={(selectedOptions) => {
              handleMultiSelectChange('production', selectedOptions);
            }}
            blank
            multi
            clearable
          />
        </div>

        <div className="col-md-4 mb15">
          <label htmlFor="search_status">Status</label>
          <StatusSelect
            id="search_status"
            stage="recruiting"
            value={statusesValue}
            placeholder="Select Statuses"
            onChange={(selectedOptions) => {
              handleMultiSelectChange('status', selectedOptions);
            }}
            isMulti
            isClearable
            isSearchable
          />
        </div>

        <div className="col-md-4 mb15">
          <label htmlFor="search_source">Source</label>
          <SourceSelect
            id="search_source"
            value={sourcesValue}
            placeholder="Select Sources"
            onChange={(selectedOptions) => {
              handleMultiSelectChange('source', selectedOptions);
            }}
            multi
            blank
            clearable
          />
        </div>

        <div className="col-md-4 mb15">
          <label htmlFor="search_rating">Rating</label>
          <RatingSelect
            id="search_rating"
            value={ratingsValue}
            placeholder="Select Ratings"
            onChange={(selectedOptions) => {
              handleMultiSelectChange('rating', selectedOptions);
            }}
            multi
            clearable
          />
        </div>

        <div className="col-md-4 mb15">
          <label htmlFor="search_company">Company</label>
          <CompanySelect
            id="search_company"
            value={companiesValue}
            placeholder="Select Companies"
            onChange={(selectedOptions) => {
              handleMultiSelectChange('currently_at', selectedOptions);
            }}
            multi
            clearable
          />
        </div>

        <div className="col-md-4 mb15">
          <label htmlFor="search_bucket">Type</label>
          <BucketSelect
            id="search_bucket"
            value={bucketsValue}
            placeholder="Select Type"
            onChange={(selectedOptions) => {
              handleMultiSelectChange('bucket', selectedOptions);
            }}
            multi
            clearable
          />
        </div>

        <div className="col-md-4 mb15">
          <label htmlFor="search_campaigns">Campaigns</label>
          <CampaignPicker
            id="search_campaigns"
            value={campaignsValue}
            placeholder="Select Campaigns"
            onChange={(selectedOptions) => {
              handleMultiSelectChange('campaign', selectedOptions);
            }}
            multi
            blank
            clearable
          />
        </div>

        <div className="col-md-4 mb15">
          <label htmlFor="search_city">City</label>
          <CitySelect
            id="search_city"
            value={citiesValue}
            placeholder="Select Cities"
            onChange={(selectedOptions) => {
              handleMultiSelectChange('city', selectedOptions);
            }}
            multi
            clearable
          />
        </div>

        <div className="col-md-4 mb15">
          <label htmlFor="search_labels">Labels</label>
          <LabelSelect
            id="search_labels"
            value={labelsValue}
            placeholder="Select Labels"
            onChange={(selectedOptions) => {
              handleMultiSelectChange('label', selectedOptions);
            }}
            multi
            blank
            clearable
          />
        </div>

        <div className={`${EngagementClasses} mb15`}>
          <label htmlFor="search_engagements">Engagement</label>
          <EngagementSelect
            id="search_engagements"
            value={EngagementSelectValue}
            placeholder="Select Engagement"
            onChange={(val) => {
              handleSingleSelectChange('engagement', val ? val.value : '');
            }}
            clearable
          />
        </div>

        {EngagementSelectValue && (
          <div className="col-lg-2 col-md-4 mb15">
            <label htmlFor="search_time_period">Time Period</label>
            <EngagementTimePeriodSelect
              id="search_time_period"
              value={engagementTimePeriodValue}
              placeholder="Select Time Period"
              onChange={(val) => handleSingleSelectChange('engagement_time_period', val ? val.value : '')}
            />
          </div>
        )}

        <div className="col-md-3 mb15">
          <label htmlFor="search_production">Production</label>
          <Tooltipable
            text={relitixProductionFilterTooltip}
            trigger="click"
            placement="right"
            tooltipContentClassName="text-left"
          >
            <FontAwesomeIcon icon={['fal', 'fa-info-circle']} size="sm" style={{ fontSize: '.6em' }} className="text-secondary ml-1" />
          </Tooltipable>
          <div className="mb5">
            <ProductionSelect
              id="search_production"
              value={productionSelectValue}
              placeholder="Select Value"
              onChange={(val) => handleSingleSelectChange('production_select_value', val ? val.value : '')}
              clearable
            />
          </div>

          {productionSelectValue && (
            <div className="form-row">
              <div className="col">
                <Input
                  type="number"
                  placeholder="From"
                  name="production_start_value"
                  value={searchData.production_start_value || ''}
                  onChange={(v) => {
                    handleSingleSelectChange('production_start_value', v.target.value);
                  }}
                />
              </div>

              <div className="col">
                <Input
                  type="number"
                  placeholder="To"
                  name="production_end_value"
                  value={searchData.production_end_value || ''}
                  onChange={(v) => {
                    handleSingleSelectChange('production_end_value', v.target.value);
                  }}
                />
              </div>
            </div>
          )}
        </div>

        <div className="col-md-3 mb15">
          <label htmlFor="search_prod_insights">Production Insights</label>
          <Tooltipable
            text={relitixProductionInsightsFilterTooltip}
            trigger="click"
            placement="right"
            tooltipContentClassName="text-left"
          >
            <FontAwesomeIcon icon={['fal', 'fa-info-circle']} size="sm" style={{ fontSize: '.6em' }} className="text-secondary ml-1" />
          </Tooltipable>
          <div className="mb5">
            <ProductionInsightsSelect
              id="search_prod_insights"
              value={productionInsightsSelectValue}
              placeholder="Select Value"
              onChange={(val) => handleSingleSelectChange('production_insights_select_value', val?.value || '')}
              clearable
            />
          </div>

          {productionInsightsSelectValue === 'effectiveness_grade' && (
            <EffectivenessGradeSelect
              value={productionInsightsListingEffGrade}
              placeholder="Select Effectiveness Grade"
              onChange={(val) => handleSingleSelectChange('production_insights_listing_eff_grade', val ? val.value : '')}
            />
          )}

          {productionInsightsSelectValue === 'current_switch_risk' && (
            <CurrentSwitchRiskSelect
              value={productionInsightsCurrentSwitchRisk}
              placeholder="Select Current Switch Risk"
              onChange={(selectedOptions) => {
                handleMultiSelectChange('production_insights_current_switch_risk', selectedOptions);
              }}
              multi
              clearable
            />
          )}

          {productionInsightsSelectValue
            && productionInsightsSelectValue !== 'current_switch_risk'
            && productionInsightsSelectValue !== 'effectiveness_grade'
            && (
              <div className="form-row">
                <div className="col">
                  <Input
                    type="number"
                    placeholder="From"
                    name="production_insights_start_value"
                    value={searchData.production_insights_start_value || ''}
                    onChange={(v) => {
                      handleSingleSelectChange('production_insights_start_value', v.target.value);
                    }}
                  />
                </div>

                <div className="col">
                  <Input
                    type="number"
                    placeholder="To"
                    name="production_insights_end_value"
                    value={searchData.production_insights_end_value || ''}
                    onChange={(v) => {
                      handleSingleSelectChange('production_insights_end_value', v.target.value);
                    }}
                  />
                </div>
              </div>
            )}
        </div>

        <div className="col-md-3 mb15">
          <label htmlFor="search_prod_location">Production Location</label>
          <Tooltipable
            text={relitixProductionLocationFilterTooltip}
            trigger="click"
            placement="right"
            tooltipContentClassName="text-left"
          >
            <FontAwesomeIcon icon={['fal', 'fa-info-circle']} size="sm" style={{ fontSize: '.6em' }} className="text-secondary ml-1" />
          </Tooltipable>
          <div className="mb5">
            <ProductionLocationSelect
              id="search_prod_location"
              value={productionLocationSelectValue}
              placeholder="Select Value"
              onChange={(val) => handleSingleSelectChange('production_location_select_value', val ? val.value : '')}
              clearable
            />
          </div>

          {productionLocationSelectValue === 'lead_office_zip' && (
            <LeadOfficeZipSelect
              value={leadOfficeZip}
              placeholder="Select Zip"
              onChange={(selectedOptions) => {
                handleMultiSelectChange('lead_office_zip', selectedOptions);
              }}
              multi
              clearable
            />
          )}

          {productionLocationSelectValue === 'closest_office_name' && (
            <ClosestOfficeSelect
              value={productionClosestOffice}
              placeholder="Select Closest Office"
              onChange={(selectedOptions) => {
                handleMultiSelectChange('production_closest_office', selectedOptions);
              }}
              multi
              clearable
            />
          )}

          {productionLocationSelectValue === 'closest_office_address' && (
            <ClosestOfficeAddressSelect
              value={productionLocationClosestOfficeAddress}
              placeholder="Select Closest Office Address"
              onChange={(selectedOptions) => {
                handleMultiSelectChange('production_location_closest_office_address', selectedOptions);
              }}
              multi
              clearable
            />
          )}

          {productionLocationSelectValue === 'center_of_production_zips' && (
            <ProductionOfficeZipSelect
              value={centerOfProductionZips}
              placeholder="Select Zip"
              onChange={(selectedOptions) => {
                handleMultiSelectChange('center_of_production_zips', selectedOptions);
              }}
              multi
              clearable
            />
          )}

          {productionLocationSelectValue
            && !(['closest_office_name', 'closest_office_address', 'center_of_production_zips', 'lead_office_zip'].includes(productionLocationSelectValue))
            && (
              <div className="form-row">
                <div className="col">
                  <Input
                    type="number"
                    placeholder="From"
                    name="production_location_start_value"
                    value={searchData.production_location_start_value || ''}
                    onChange={(v) => {
                      handleSingleSelectChange('production_location_start_value', v.target.value);
                    }}
                  />
                </div>

                <div className="col">
                  <Input
                    type="number"
                    placeholder="To"
                    name="production_location_end_value"
                    value={searchData.production_location_end_value || ''}
                    onChange={(v) => {
                      handleSingleSelectChange('production_location_end_value', v.target.value);
                    }}
                  />
                </div>
              </div>
            )}
        </div>

        <div className="col-md-3 mb15">
          <label htmlFor="search_prod_dates">Production Dates</label>
          <Tooltipable
            text={relitixProductionDatesFilterTooltip}
            trigger="click"
            placement="right"
            tooltipContentClassName="text-left"
          >
            <FontAwesomeIcon icon={['fal', 'fa-info-circle']} size="sm" style={{ fontSize: '.6em' }} className="text-secondary ml-1" />
          </Tooltipable>
          <div className="mb5">
            <ProductionDatesSelect
              id="search_prod_dates"
              value={productionDatesSelectValue}
              placeholder="Select Value"
              onChange={(val) => handleSingleSelectChange('production_dates_select_value', val ? val.value : '')}
              clearable
            />
          </div>

          {productionDatesSelectValue && (
            <div className="form-row">
              <div className="col">
                <DateTimePicker
                  id="production_start_date"
                  placeholder="From"
                  showClear
                  format="ll"
                  pickerType="date"
                  value={Moment(productionStartDate)?.format('ll')}
                  onChange={(date) => {
                    handleDateSelectChange('production_start_date', date);
                  }}
                />
              </div>

              <div className="col">
                <DateTimePicker
                  id="production_end_date"
                  placeholder="To"
                  showClear
                  format="ll"
                  pickerType="date"
                  value={Moment(productionEndDate)?.format('ll')}
                  onChange={(date) => {
                    handleDateSelectChange('production_end_date', date);
                  }}
                />
              </div>
            </div>
          )}
        </div>

        <div className="mt-2 w-md-20">
          <label htmlFor="search_referred">Referred</label>
          <div className="mb5">
            <DateTimePicker
              id="referred_date_start"
              placeholder="Start"
              showClear
              format="MMMM D, YYYY"
              pickerType="date"
              value={Moment(referredAtStart)?.format('MMMM D, YYYY')}
              onChange={(date) => {
                handleDateSelectChange('referred_at_start', date);
              }}
            />
          </div>

          <DateTimePicker
            id="referred_date_end"
            placeholder="End"
            showClear
            format="MMMM D, YYYY"
            pickerType="date"
            value={Moment(referredAtEnd)?.format('MMMM D, YYYY')}
            onChange={(date) => {
              handleDateSelectChange('referred_at_end', date);
            }}
          />
        </div>

        <div className="mt-2 w-md-20">
          <label htmlFor="task_due_date_start">Task Due Date</label>
          <div className="mb5">
            <DateTimePicker
              id="task_due_date_start"
              placeholder="Start"
              showClear
              format="MMMM D, YYYY"
              pickerType="date"
              value={Moment(dueDateAtStart)?.format('MMMM D, YYYY')}
              onChange={(date) => {
                handleDateSelectChange('due_date_at_start', date);
              }}
            />
          </div>

          <DateTimePicker
            id="task_due_date_end"
            placeholder="End"
            showClear
            format="MMMM D, YYYY"
            pickerType="date"
            value={Moment(dueDateAtEnd)?.format('MMMM D, YYYY')}
            onChange={(date) => {
              handleDateSelectChange('due_date_at_end', date);
            }}
          />
        </div>

        <div className="mt-2 w-md-20">
          <label htmlFor="last_activity_start">Last Activity</label>
          <div className="mb5">
            <DateTimePicker
              id="last_activity_start"
              placeholder="Start"
              showClear
              format="MMMM D, YYYY"
              pickerType="date"
              value={Moment(lastActivityAtStart)?.format('MMMM D, YYYY')}
              onChange={(date) => {
                handleDateSelectChange('last_activity_at_start', date);
              }}
            />
          </div>

          <DateTimePicker
            id="last_activity_end"
            placeholder="End"
            showClear
            format="MMMM D, YYYY"
            pickerType="date"
            value={Moment(lastActivityAtEnd)?.format('MMMM D, YYYY')}
            onChange={(date) => {
              handleDateSelectChange('last_activity_at_end', date);
            }}
          />
        </div>

        <div className="mt-2 w-md-20">
          <label htmlFor="last_appt_start">Last Appt</label>
          <div className="mb5">
            <DateTimePicker
              id="last_appt_start"
              placeholder="Start"
              showClear
              format="MMMM D, YYYY"
              pickerType="date"
              value={Moment(lastAppointmentAtStart)?.format('MMMM D, YYYY')}
              onChange={(date) => {
                handleDateSelectChange('last_appointment_at_start', date);
              }}
            />
          </div>

          <DateTimePicker
            id="last_appt_end"
            placeholder="End"
            showClear
            format="MMMM D, YYYY"
            pickerType="date"
            value={Moment(lastAppointmentAtEnd)?.format('MMMM D, YYYY')}
            onChange={(date) => {
              handleDateSelectChange('last_appointment_at_end', date);
            }}
          />
        </div>

        <div className="mt-2 w-md-20">
          <label htmlFor="birthday_start">Birthday On</label>
          <div className="mb5">
            <DateTimePicker
              id="birthday_start"
              placeholder="Start"
              showClear
              format="MMMM D"
              pickerType="date"
              value={Moment(birthdayStart)?.format('MMMM D')}
              onChange={(date) => {
                handleDateSelectChange('birthday_start', date);
              }}
            />
          </div>

          <DateTimePicker
            id="birthday_end"
            placeholder="End"
            showClear
            format="MMMM D"
            pickerType="date"
            value={Moment(birthdayEnd)?.format('MMMM D')}
            onChange={(date) => {
              handleDateSelectChange('birthday_end', date);
            }}
          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-12">
          <button type="button" className="btn btn-sm btn-secondary" onClick={() => { clearSearch(); }}>
            Clear search
          </button>
        </div>
      </div>
    </div>
  );
};

RecruitingSearchFilters.defaultProps = {
  searchData:               {},
  clearSearch:              () => false,
  handleSingleSelectChange: () => false,
  handleMultiSelectChange:  () => false,
  handleDateSelectChange:   () => false,
  handleCheckBoxClick:      () => false,
};

RecruitingSearchFilters.propTypes = {
  searchData:               PropTypes.shape({}),
  clearSearch:              PropTypes.func,
  hideFilters:              PropTypes.func.isRequired,
  handleSingleSelectChange: PropTypes.func,
  handleMultiSelectChange:  PropTypes.func,
  handleDateSelectChange:   PropTypes.func,
  handleCheckBoxClick:      PropTypes.func,
};

export default RecruitingSearchFilters;
