import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import { PropTypes } from 'prop-types';
import { Collapse } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactDOM from 'react-dom';

import { Link } from 'react-router-dom';
import brokerbit from '~/lib/brokerbit';
import Tooltipable from '~/components/effects/tooltipable';
import DocumentActions from '~/actions/document_actions';
import DocumentStore from '~/stores/document_store';
import EditDocumentModal from '~/components/modals/edit_document_modal';
import DocumentUploader from '~/components/navigation/document_uploader';
import DocumentLimitAlertChecker from '~/components/shared/document_limit_alert_checker';
import classNames from 'classnames';
import LeadDrawerStore from '~/stores/lead_drawer_store';

const LeadDrawerDocuments = ({ leadId }) => {
  const [state, setState] = useState({
    leadID:                  leadId,
    isOpen:                  false,
    documents:               [],
    pagination:              { total_pages: 1, current_page: 1 },
    loading:                 false,
    lastDocumentStoreAction: null,
  });
  const [showLimitAlert, setShowLimitAlert] = useState(false);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const context = GlobalContainer.product() === 'recruiting' ? 'recruiter' : GlobalContainer.product();

  const {
    documents = [],
    pagination = { total_pages: 1, current_page: 1 },
    isOpen,
    loading,
  } = state;

  const { current_page, total_pages } = pagination;

  const { currentUser } = Rails.helpers;

  const isAdminOrStaff = currentUser.role.name === 'admin' || currentUser.role.name === 'staff';

  const uploaderRef = useRef(null);
  const abortControllerRef = useRef(null);
  const { leadID } = state;

  const loadDocuments = useCallback((page = 1) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();

    setTimeout(() => {
      DocumentActions.loadDocumentsWithRelatedContact(leadID, page);
    }, 0);
  }, [leadID]);


  const onChange = useCallback(() => {
    const newState = DocumentStore.getState();
    const { lastDocumentStoreAction, pagination } = newState;
    let { current_page } = pagination;

    if (lastDocumentStoreAction === 'createDocumentDone' ||
      lastDocumentStoreAction === 'updateDocumentDone' ||
      lastDocumentStoreAction === 'deleteDocumentDone') {
      current_page = lastDocumentStoreAction === 'createDocumentDone' ? 1 : current_page || 1;
      loadDocuments(current_page);
    }

    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }, [loadDocuments]);

  const onLeadDrawerChange = useCallback(() => {
    const storeState = LeadDrawerStore.getState();
    const { leadDrawerStoreAction } = storeState;

    const validActions = ['createResumeDone', 'updateResumeDone', 'deleteResumeDone'];
    if (validActions.includes(leadDrawerStoreAction)) {
      loadDocuments();
    }
  }, [loadDocuments]);

  useEffect(() => {
    const storeListener = DocumentStore.addListener(onChange);
    const leadDrawerListener = LeadDrawerStore.addListener(onLeadDrawerChange);
    loadDocuments();

    return () => {
      storeListener.remove();
      leadDrawerListener.remove();
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [onChange, loadDocuments, onLeadDrawerChange]);

  const onPageChange = (data) => {
    const newPage = data.selected + 1;
    loadDocuments(newPage);
  };

  const handleChangeInternalClick = (document) => {
    const { internal } = document;

    DocumentActions.updateDocument({
      ...document,
      internal: !internal,
    }, leadID);
  };

  const handleDocumentDelete = (e, document) => {
    e.preventDefault();
    brokerbit.confirmBox({
      message:  'Are you sure you want to delete this document?',
      callback: (ok) => {
        if (ok) {
          DocumentActions.deleteDocument(document, leadID);
        }
      },
    });
  };

  const handleEditDocumentClick = (e, document) => {
    e.preventDefault();
    setSelectedDocument(document);
    setIsEditModalOpen(true);
  };

  const handleCloseModal = useCallback(() => {
    setIsEditModalOpen(false);
    setSelectedDocument(null);
  }, []);

  const handleDownloadDocument = async (e, document) => {
    e.preventDefault();
    try {
      const downloadUrl = `${Rails.apiUrl}/api/v1/documents/${document.id}/download?auth=${Rails.helpers.authToken}`;

      const link = window.document.createElement('a');
      link.href = downloadUrl;
      window.document.body.appendChild(link);
      link.click();
      window.document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };

  const onCollapseClick = (e) => {
    e.preventDefault();

    if (!isOpen) {
      loadDocuments();
    }
    setState((prevState) => ({
      ...prevState,
      isOpen: !prevState.isOpen,
    }));
  };

  const handleFileSelect = (e) => {
    e.preventDefault();
    if (uploaderRef.current) {
      uploaderRef.current.handleFileSelect();
    }
  };

  const onUploadComplete = () => {
    loadDocuments();
  };

  const onLoadingStateChange = (isLoading) => {
    setState((prevState) => ({
      ...prevState,
      loading: isLoading,
    }));
  };

  const renderDocument = useCallback((document) => {
    const buttonContainerStyle = {
      display:        'flex',
      gap:            '8px',
      justifyContent: 'flex-end',
    };

    const documentUrl = `/documents/${document.id}`;

    let uploadedAtDate = null;
    let isValidDate = false;

    if (document.uploaded_at) {
      uploadedAtDate = new Date(document.uploaded_at);
      isValidDate = !isNaN(uploadedAtDate.getTime());
    }

    return (
      <tr key={document.id}>
        <td>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon icon="far fa-file-alt" className="mr-2 text-info" size="2x" />
            <div>
              <a href={documentUrl} target="_blank" rel="noopener noreferrer" className="text-info">
                <strong>{document.title}</strong>
                <FontAwesomeIcon icon="external-link-alt" className="ml-2" />
              </a>

              {isValidDate && (
                <div className="text-muted" style={{ fontSize: '0.9em' }}>
                  {uploadedAtDate.toLocaleDateString()}
                </div>
              )}
            </div>
          </div>
        </td>
        <td className="text-right">
          <div style={buttonContainerStyle}>
            <Tooltipable text="Download">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={(e) => handleDownloadDocument(e, document)}
              >
                <FontAwesomeIcon icon={['far', 'download']} size="sm" />
              </button>
            </Tooltipable>

            {isAdminOrStaff && (
              <>
                <Tooltipable text="Edit Document">
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={(e) => handleEditDocumentClick(e, document)}
                  >
                    <FontAwesomeIcon icon={['far', 'edit']} size="sm" />
                  </button>
                </Tooltipable>
                <Tooltipable text={document.internal ? 'Shared with admin/staff users only' : 'Shared with the referring agent/affiliate user'}>
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={() => handleChangeInternalClick(document)}
                  >
                    <FontAwesomeIcon
                      icon={document.internal ? 'far fa-eye-slash' : 'far fa-eye'}
                      size="sm"
                    />
                  </button>
                </Tooltipable>
                <Tooltipable text="Delete Document">
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={(e) => handleDocumentDelete(e, document)}
                  >
                    <FontAwesomeIcon icon={['far', 'trash-alt']} size="sm" />
                  </button>
                </Tooltipable>
              </>
            )}
          </div>
        </td>
      </tr>
    );
  }, [
    isAdminOrStaff,
    handleDownloadDocument,
    handleEditDocumentClick,
    handleChangeInternalClick,
    handleDocumentDelete,
  ]);

  return (
    <div>
      <div className="pull-right">
        <button
          type="button"
          className="btn btn-secondary btn-sm"
          onClick={onCollapseClick}
          disabled={loading}
        >
          {isOpen ? (
            <FontAwesomeIcon icon={['far', 'minus']} />
          ) : (
            <FontAwesomeIcon icon={['far', 'plus']} />
          )}
        </button>
        {isAdminOrStaff ? (
          <DocumentLimitAlertChecker
            showLimitAlert={showLimitAlert}
            setShowLimitAlert={setShowLimitAlert}
          >
            <Tooltipable text="Upload Document">
              <button
                type="button"
                className="btn btn-secondary btn-sm ml-2"
                onClick={handleFileSelect}
                disabled={loading}
                cursor={loading ? 'not-allowed' : 'pointer'}
              >
                {loading ? (
                  <FontAwesomeIcon icon="spinner" pulse />
                ) : (
                  <FontAwesomeIcon icon={['far', 'upload']} />
                )}
              </button>
            </Tooltipable>
          </DocumentLimitAlertChecker>
        ) : null}
      </div>

      <h4 className="mb15">Documents</h4>
      {loading && <p>Uploading document...</p>}
      <Collapse isOpen={isOpen}>
        {documents.length === 0 && <p>No Documents</p>}
        <div className="table-responsive">
          <table className={classNames('table', { 'mt-3': documents.length === 1 })}>
            <tbody>{documents.map(renderDocument)}</tbody>
          </table>
        </div>
        <div className="mb-2 row">
          {total_pages > 1 && (
            <div className="col-md-auto">
              <nav>
                <ReactPaginate
                  forcePage={current_page - 1}
                  pageCount={total_pages}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={2}
                  containerClassName="pagination"
                  activeClassName="active"
                  breakLabel="..."
                  breakLinkClassName="page-link disabled"
                  breakClassName="page-item"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  disabledClassName="disabled"
                  onPageChange={onPageChange}
                  hrefBuilder={(page) => `#page=${page}`}
                  previousLabel={Rails.isMobile ? '<' : 'Previous'}
                  nextLabel={Rails.isMobile ? '>' : 'Next'}
                />
              </nav>
            </div>
          )}
          <div className="col-md-auto mt-2 ml-2">
            <Link to={`/${context}/documents`}>
              View all documents
            </Link>
          </div>
        </div>
      </Collapse>

      {isEditModalOpen &&
        ReactDOM.createPortal(
          <EditDocumentModal
            documentID={selectedDocument.id}
            containerID="secondary-modal"
            modalClass="modal modal-overlay"
            dialogClass="modal-dialog modal-dialog-centered"
            onClose={handleCloseModal}
            leadID={leadID}
          />,
          document.getElementById('secondary-modal'),
        )}

      <DocumentUploader
        ref={uploaderRef}
        onUploadComplete={onUploadComplete}
        onLoadingStateChange={onLoadingStateChange}
        setShowLimitAlert={setShowLimitAlert}
        leadID={leadID}
      />
    </div>
  );
};

LeadDrawerDocuments.defaultProps = {
  leadID: null,
};

LeadDrawerDocuments.propTypes = {
  leadID: PropTypes.number.isRequired,
};

export default LeadDrawerDocuments;
