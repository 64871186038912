import autoBind            from 'react-autobind';
import qs                  from 'qs';
import { v4 as uuidv4 }    from 'uuid';
import classNames          from 'classnames';
import React, { Component } from 'react';
import Mousetrap           from 'mousetrap';
import { PropTypes }       from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LeadDrawerStore from '~/stores/lead_drawer_store';

let leadStoreListener;

class Drawer extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    const $drawer = $(this.modal);

    Mousetrap.bind(['left'], this.handlePrevAction);
    Mousetrap.bind(['right'], this.handleNextAction);
    Mousetrap.bind(['esc'], this.handleCloseAction);

    $drawer.on('shown.bs.modal', () => {
      if (Rails.isMobile) {
        // this is to fix mobile drawer background scroll by hiding it
        $('.content-container').hide();
      }
    });

    $drawer.modal({ keyboard: false, backdrop: 'static' });

    // listen to LeadDrawerStore changes
    leadStoreListener = LeadDrawerStore.addListener(this.onChange);
  }

  componentWillUnmount() {
    const $drawer = $(this.modal);

    Mousetrap.unbind(['left', 'right', 'esc']);

    $drawer.on('hidden.bs.modal', () => {
      if (Rails.isMobile) {
        // this is to fix mobile drawer background scroll by hiding it
        $('.content-container').show();
      }
      $(this).data('bs.modal', null);
    }).modal('hide');

    // remove listener to LeadDrawerStore changes on Unmount
    if (leadStoreListener) leadStoreListener.remove();
  }

  handlePrevAction() {
    const { handlePrevClick, reportItemIndex } = this.props;
    if (typeof handlePrevClick === 'function') { handlePrevClick(reportItemIndex); }
  }

  handleNextAction() {
    const { handleNextClick, reportItemIndex } = this.props;
    if (typeof handleNextClick === 'function') { handleNextClick(reportItemIndex); }
  }

  handleCloseAction(e) {
    const { helpers } = this.context;
    const params = GlobalContainer.urlParams();

    delete params.lead;
    delete params.tab;

    const searchQuery = qs.stringify(params, {
      arrayFormat: 'brackets',
    });

    e.preventDefault();
    helpers.closeDrawer();
    browserHistory.push(
      `${location.pathname}?${decodeURI(searchQuery)}`,
      {},
    );
  }

  // when LeadDrawerStore changes...
  onChange() {
    const { leadDrawerStoreAction } = LeadDrawerStore.getState();
    const { helpers } = this.context;

    if (leadDrawerStoreAction === 'reassignLeadDone') {
      setTimeout(() => {
        helpers.closeDrawer();
      }, 1);
    }
  }

  render() {
    const {
      id,
      size,
      pop,
      url,
      children,
      contentClass,
      item,
      showNextPrev,
      handlePrevClick,
      handleNextClick,
      scrollable,
      isDialerOpen,
    } = this.props;

    const drawerClass = classNames(
      `drawer drawer-${size} modal show hide-scrollbar`,
    );
    const drawerContentClass = classNames(
      'modal-content content',
      contentClass,
      scrollable && 'scrollable',
    );

    return (
      <div
        className={drawerClass}
        id={`drawer-${id}`}
        ref={(modal) => this.modal = modal}
        style={{ overflow: 'hidden auto', width: '100vw' }}
        role="dialog"
        data-url={url}
        data-pop={pop}
        data-easein="pulse"
      >
        <div className="controls">
          {!isDialerOpen ? (
            <a
              href="#"
              onClick={this.handleCloseAction}
            >
              <span className="fa-stack fa-lg">
                <FontAwesomeIcon
                  icon={['fas', 'fa-circle']}
                  className="fa-stack-2x"
                />
                <FontAwesomeIcon
                  icon={['far', 'fa-times']}
                  inverse
                  className="fa-stack-1x"
                />
              </span>
            </a>
          ) : (
            <span className="fa-stack fa-lg disabled">
              <FontAwesomeIcon
                icon={['fas', 'fa-circle']}
                className="fa-stack-2x"
              />
              <FontAwesomeIcon
                icon={['far', 'fa-times']}
                inverse
                className="fa-stack-1x"
              />
            </span>
          )}
          {showNextPrev && (
            <span>
              {handlePrevClick && !isDialerOpen ? (
                <a
                  href="#prev-drawer"
                  aria-label="Previous"
                  className="icon prev"
                  onClick={this.handlePrevAction}
                >
                  <span className="fa-stack fa-lg">
                    <FontAwesomeIcon
                      icon={['fas', 'fa-circle']}
                      className="fa-stack-2x"
                    />
                    <FontAwesomeIcon
                      icon={['far', 'fa-chevron-left']}
                      inverse
                      className="fa-stack-1x"
                    />
                  </span>
                </a>
              ) : (
                <span className="fa-stack fa-lg icon prev disabled">
                  <FontAwesomeIcon
                    icon={['fas', 'fa-circle']}
                    className="fa-stack-2x"
                  />
                  <FontAwesomeIcon
                    icon={['far', 'fa-chevron-left']}
                    inverse
                    className="fa-stack-1x"
                  />
                </span>
              )}

              {handleNextClick && !isDialerOpen ? (
                <a
                  href="#next-drawer"
                  aria-label="Next"
                  className="icon next"
                  onClick={this.handleNextAction}
                >
                  <span className="fa-stack fa-lg">
                    <FontAwesomeIcon
                      icon={['fas', 'fa-circle']}
                      className="fa-stack-2x"
                    />
                    <FontAwesomeIcon
                      icon={['far', 'fa-chevron-right']}
                      inverse
                      className="fa-stack-1x"
                    />
                  </span>
                </a>
              ) : (
                <span className="fa-stack fa-lg icon next disabled">
                  <FontAwesomeIcon
                    icon={['fas', 'fa-circle']}
                    className="fa-stack-2x"
                  />
                  <FontAwesomeIcon
                    icon={['far', 'fa-chevron-right']}
                    inverse
                    className="fa-stack-1x"
                  />
                </span>
              )}
            </span>
          )}
        </div>

        <div className="modal-dialog" role="document">
          <div className={drawerContentClass}>
            <a
              href="#"
              onClick={this.handleCloseAction}
              className="d-sm-none"
              style={{
                position: 'absolute',
                top:      '15px',
                right:    '15px',
                zIndex:   '1051',
              }}
            >
              <span className="fa-stack">
                <FontAwesomeIcon
                  icon={['fas', 'fa-circle']}
                  className="fa-stack-2x"
                />
                <FontAwesomeIcon
                  icon={['far', 'fa-times']}
                  inverse
                  className="fa-stack-1x"
                />
              </span>
            </a>

            {children}
          </div>
        </div>
      </div>
    );
  }
}

Drawer.contextTypes = {
  helpers: PropTypes.shape({}),
};

Drawer.defaultProps = {
  id:              uuidv4(),
  size:            'normal',
  pop:             true,
  url:             null,
  containerID:     'primary-modal',
  contentClass:    'modal-content content',
  children:        [],
  item:            null,
  reportItemIndex: null,
  showNextPrev:    false,
  handlePrevClick: null,
  handleNextClick: null,
  isDialerOpen:    false,
};

Drawer.propTypes = {
  id:              PropTypes.string,
  size:            PropTypes.oneOf(['normal', 'wide']),
  pop:             PropTypes.bool,
  url:             PropTypes.string,
  containerID:     PropTypes.string,
  contentClass:    PropTypes.string,
  children:        PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
  item:            PropTypes.shape({}),
  reportItemIndex: PropTypes.number,
  showNextPrev:    PropTypes.bool,
  handlePrevClick: PropTypes.func,
  handleNextClick: PropTypes.func,
  isDialerOpen:    PropTypes.bool,
};

export default Drawer;
