import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CampaignForm from '~/components/pages/account/forms/campaign_form';

const CampaignStepsTab = ({
  campaign,
  campaignSettings,
  loadingCampaign,
  campaignErrors,
  validCampaign,
  selectingTemplate,
  onCampaignStateChange,
  history,
  setCampaignErrors,
  setErrors,
}) => {
  const { timezone } = Rails.helpers.currentTeam;

  const renderCampaignForm = () => {
    if (loadingCampaign) {
      return (
        <div className="text-center">
          <FontAwesomeIcon testid="spinner" icon={['far', 'spinner']} spin size="lg" />
        </div>
      );
    }

    return (
      <CampaignForm
        campaign={campaign}
        campaignSettings={campaignSettings}
        validCampaign={validCampaign}
        selectingTemplate={selectingTemplate}
        onCampaignStateChange={onCampaignStateChange}
        history={history}
        setCampaignErrors={setCampaignErrors}
        setErrors={setErrors}
      />
    );
  };

  return (
    <>
      <div className="mb10">
        <div className="mt-4">
          {campaignErrors}
        </div>

        <p className="mt-3">Note:</p>
        <small className="text-grey">
          <ul>
            {!campaign?.new_engine && (
              <li className="mt-1">
                Drip emails and texts are sent at randomized times between 9am-12pm (
                  {Moment().tz(timezone).format('z')}
                ) to make them seem more personal.
              </li>
            )}
            <li className="mt-1">
              Existing smart campaign subscriptions will be paused whenever a Lead or Agent is moved between the states of Recruiting, Retention, or Archived.
            </li>
          </ul>
        </small>
      </div>

      {renderCampaignForm()}
    </>
  );
};

CampaignStepsTab.defaultProps = {
  campaign:         null,
  campaignSettings: null,
  campaignErrors:   null,
};

CampaignStepsTab.propTypes = {
  campaign:              PropTypes.shape({}),
  campaignSettings:      PropTypes.shape({}),
  campaignErrors:        PropTypes.shape({}),
  history:               PropTypes.shape({}).isRequired,
  loadingCampaign:       PropTypes.bool.isRequired,
  validCampaign:         PropTypes.bool.isRequired,
  selectingTemplate:     PropTypes.bool.isRequired,
  onCampaignStateChange: PropTypes.func.isRequired,
  setCampaignErrors:     PropTypes.func.isRequired,

};

export default CampaignStepsTab;
