import React, {
  useState, useEffect, useCallback,
} from 'react';
import { Link }            from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEmpty             from 'lodash/isEmpty';

import GlobalContainer from '~/components/global_container';
import CampaignActions from '~/actions/campaign_actions';
import CampaignStore   from '~/stores/campaign_store';
import CampaignsTable  from './CampaignsTable';

const ListingCampaigns = () => {
  const CampaignLibrariesUri = GlobalContainer.productUri('/campaign_libraries');
  const CampaignTriggersUri = GlobalContainer.productUri('/campaign_triggers');
  const CampaignNewUri = GlobalContainer.productUri('/campaigns/new');

  const [loadingCampaigns, setLoadingCampaigns] = useState(false);
  const [campaigns, setCampaigns] = useState([]);

  const onCampaignStoreChange = useCallback(() => {
    const {
      loadingCampaigns: storeLoadingCampaigns,
      campaigns: storeCampaigns,
      lastCampaignStoreAction,
    } = CampaignStore.getState();

    const LOADING_ACTIONS = [
      'loadCampaigns',
      'loadCampaignsDone',
      'loadCampaignsFail',
    ];

    if (LOADING_ACTIONS.includes(lastCampaignStoreAction)) {
      setLoadingCampaigns(storeLoadingCampaigns);
    }

    if (lastCampaignStoreAction === 'loadCampaignsDone') {
      setCampaigns(storeCampaigns);
    }
  }, []);

  const deleteCampaign = useCallback((campaign) => {
    if (isEmpty(campaign)) return;

    CampaignActions.deleteCampaign(campaign);
  }, []);

  useEffect(() => {
    const campaignStoreListener = CampaignStore.addListener(onCampaignStoreChange);
    CampaignActions.loadCampaigns();

    return () => {
      if (campaignStoreListener) campaignStoreListener.remove();
    };
  }, [onCampaignStoreChange]);

  const renderCampaignsTable = () => {
    if (loadingCampaigns) {
      return (
        <div className="text-center card-block">
          <FontAwesomeIcon testid="spinner" icon={['far', 'spinner']} pulse size="lg" />
        </div>
      );
    }

    if (isEmpty(campaigns)) {
      return (
        <div className="text-grey-dark card-block text-center">
          Select compose to add a new campaign.
        </div>
      );
    }

    return (
      <CampaignsTable
        campaigns={campaigns}
        deleteCampaign={deleteCampaign}
      />
    );
  };

  return (
    <div className="card campaign">
      <div className="card-block">
        <h4 className="mb15">
          Smart Campaigns
          <div className="pull-right">
            <Link className="btn btn-secondary mr3" to={CampaignLibrariesUri}>
              Campaign Library
            </Link>

            <Link className="btn btn-secondary mr3" to={CampaignTriggersUri}>
              Campaign Triggers
            </Link>

            <Link className="btn btn-secondary" to={CampaignNewUri}>
              <FontAwesomeIcon icon={['fas', 'pencil-alt']} className="mr5" />
              {' '}
              Compose Campaign
            </Link>
          </div>
        </h4>
      </div>

      {renderCampaignsTable()}
    </div>
  );
};

export default ListingCampaigns;
