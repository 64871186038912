export const getAllowedDomains = () => {
  const webAppUrl = process.env.WEB_APP_URL || 'http://localhost:3000';
  const baseUrl = new URL(webAppUrl);
  const allowedDomains = [
    baseUrl.host,
    'localhost:3000',
  ].map((domain) => domain.replace('.', '\\.'));
  return allowedDomains;
};

export const isValidVideoURL = (url) => {
  // UUID v4 pattern
  const uuidPattern = '[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}';
  const allowedDomains = getAllowedDomains();

  const pattern = new RegExp(
    `^https?://(${allowedDomains.join('|')})/video/${uuidPattern}$`,
    'i',
  );

  return pattern.test(url);
};

export const isValidDocumentURL = (url) => {
  const allowedDomains = getAllowedDomains();

  // Document URL pattern:
  // Matches:
  // domain.com/d/123                  (no auth param)
  // domain.com/d/123/?auth=some_token (with auth param)
  //
  // Explanation:
  // - ^https?://(${allowedDomains.join('|')})  : must start with allowed domain
  // - /d/\\d+ : path with 'd' followed by one or more digits (the doc ID)
  // - (/?auth=[^\\s]+)? : optional group for auth parameter

  const docPattern = new RegExp(
    `^https?://(${allowedDomains.join('|')})/d/\\d+(/\\?auth=[^\\s]+)?$`,
    'i',
  );

  return docPattern.test(url);
};
