import React            from 'react';
import BlankPage        from '~/components/pages/blank_page';
import FrontSideBar     from '~/components/navigation/front_side_bar';
import ListingCampaigns from './components/ListingCampaigns';

const ManageCampaigns = () => {
  const { currentTeam } = Rails.helpers;
  if (!currentTeam) return null;

  return (
    <BlankPage title="Campaigns - Brokerkit">
      <div className="row mb15">
        <FrontSideBar />

        <main className="mr-sm-auto col-md-8 col-lg-9 col-xl-8">
          <ListingCampaigns />
        </main>
      </div>
    </BlankPage>
  );
};

export default ManageCampaigns;
