import React from 'react';
import { PropTypes } from 'prop-types';

import BlankPage from '~/components/pages/blank_page';
import FrontSideBar from '~/components/navigation/front_side_bar';
import ComposingCampaign from './components/ComposingCampaign';

const EditCampaign = ({ match }) => {
  const { currentTeam } = Rails.helpers;
  if (!currentTeam) return null;

  return (
    <BlankPage title="Campaigns - Brokerkit">
      <div className="row">
        <FrontSideBar />

        <div className="right-side-page-md">
          <ComposingCampaign campaignID={match.params.id} />
        </div>
      </div>
    </BlankPage>
  );
};

EditCampaign.defaultProps = {
  match: {
    params: {
      id: null,
    },
  },
};

EditCampaign.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export default EditCampaign;
