import React, { useState, useEffect, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import size from 'lodash/size';

import CampaignActions from '~/actions/campaign_actions';
import CampaignStore from '~/stores/campaign_store';
import DonateCampaignOriginSelect from '~/components/forms/donate_campaign_origin_select';

const DonateCampaignModal = ({ campaignId, show, onClose }) => {
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [origin, setOrigin] = useState(null);

  const onChange = useCallback(() => {
    const campaignState = CampaignStore.getState();
    const { lastCampaignStoreAction, message, errors: stateErrors } = campaignState;

    if (lastCampaignStoreAction === 'donateCampaignDone') {
      GlobalContainer.notify(message, 'success');
      if (onClose) onClose();
    }

    if (lastCampaignStoreAction === 'donateCampaignFail') {
      setErrors(stateErrors);
      setSubmitting(false);
    }
  }, [onClose]);

  useEffect(() => {
    const campaignStoreListener = CampaignStore.addListener(onChange);
    return () => {
      if (campaignStoreListener) campaignStoreListener.remove();
    };
  }, [onChange]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      setSubmitting(true);
      CampaignActions.donateCampaign(campaignId, origin);
    }
  };

  const handleOriginSelect = (selectedValue) => {
    setOrigin(selectedValue?.value || null);
  };

  const validate = () => {
    const validationsErrors = {};

    if (!origin) validationsErrors.origin = "Can't be empty";
    setErrors(validationsErrors);

    return size(validationsErrors) === 0;
  };

  return (
    <Modal show={show} onHide={onClose} backdrop="static" keyboard={false} size="lg">
      <form method="POST" onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            Please confirm you want to donate this campaign to the campaign library
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group mr5">
            <DonateCampaignOriginSelect
              placeholder="Please, make a selection"
              onChange={handleOriginSelect}
              value={origin}
              className={errors.origin ? 'has-error' : ''}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={onClose}>Cancel</button>
          {submitting ? (
            <button type="button" className="btn btn-primary disabled" disabled>
              <FontAwesomeIcon icon={['far', 'spinner']} pulse className="mr5" />
              {' '}
              Donating ...
            </button>
          ) : (
            <button type="submit" className="btn btn-primary">Donate</button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

DonateCampaignModal.propTypes = {
  campaignId: PropTypes.number.isRequired,
  show:       PropTypes.bool.isRequired,
  onClose:    PropTypes.func.isRequired,
};

export default DonateCampaignModal;
