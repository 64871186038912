import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SettingsUpgradePopover = ({
  children,
  placement,
}) => {
  const [showPopover, setShowPopover] = useState(false);
  const popoverTarget = useRef(null);

  const togglePopover = useCallback((event) => {
    setShowPopover((prevShow) => !prevShow);
    popoverTarget.current = event.target;
  }, []);

  const handleClose = useCallback(() => {
    setShowPopover(false);
  }, []);

  return (
    <>
      {children({ togglePopover })}

      <Overlay
        show={showPopover}
        target={popoverTarget.current}
        placement={placement}
        containerPadding={40}
        onHide={handleClose}
        flip
      >
        <Popover id="popover-upgrade" className="border-0">
          <button
            type="button"
            className="close position-absolute popover-close-button"
            onClick={handleClose}
          >
            <FontAwesomeIcon icon={['fas', 'times']} size="sm" />
          </button>

          <Popover.Content className="text-white bg-green-lighter rounded">
            <h3>Upgrade Your Subscription</h3>

            <p className="text-dark mb-3">
              Automatically pause individual campaign subscriptions when a contact replies
              <br />
              while keeping other contacts&apos; campaign subscriptions running smoothly.
            </p>

            <button
              type="button"
              className="btn btn-success"
              onClick={() => window.open('/billing', '_blank', 'noopener,noreferrer')}
            >
              Upgrade Your Subscription
            </button>
          </Popover.Content>
        </Popover>
      </Overlay>
    </>
  );
};

SettingsUpgradePopover.propTypes = {
  children:  PropTypes.func.isRequired,
  placement: PropTypes.string,
};

SettingsUpgradePopover.defaultProps = {
  placement: 'auto',
};

export default SettingsUpgradePopover;
