import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';

import GlobalContainer from '~/components/global_container';
import CampaignActions from '~/actions/campaign_actions';
import CampaignTabs from './CampaignTabs/CampaignTabs';

const ComposingCampaign = ({ campaignID = null }) => {
  const history = useHistory();
  const campaignsUri = GlobalContainer.productUri('/campaigns');
  const [loadingCampaign, setLoadingCampaign] = useState(!!campaignID);
  const [validCampaign, setValidCampaign] = useState(true);
  const [selectingTemplate, setSelectingTemplate] = useState(true);
  const [campaign, setCampaign] = useState(null);
  const [campaignSettings, setCampaignSettings] = useState(null);
  const [campaignErrors, setCampaignErrors] = useState(null);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if (campaignID) {
      CampaignActions.loadCampaign(campaignID, onCampaignLoad);
    }
  }, [campaignID]);

  const onCampaignLoad = (loadedCampaign) => {
    setLoadingCampaign(false);
    setSelectingTemplate(false);
    setCampaign(loadedCampaign);
  };

  const onCampaignStateChange = (stateChange) => {
    if (stateChange.validCampaign !== undefined) {
      setValidCampaign(stateChange.validCampaign);
    }
    if (stateChange.selectingTemplate !== undefined) {
      setSelectingTemplate(stateChange.selectingTemplate);
    }
    if (stateChange.campaign !== undefined) {
      setCampaign(stateChange.campaign);
    }
    if (stateChange.campaignSettings !== undefined) {
      setCampaignSettings(stateChange.campaignSettings);
    }
  };

  const handleSetCampaignErrors = (errs) => {
    setCampaignErrors(errs);
  };

  const handleSetErrors = (errs) => {
    setErrors(errs);
  };

  return (
    <div className="card campaign">
      <div className="card-block">
        <h4 className="mb15">
          <div className="pull-right mb-3">
            <Link to={campaignsUri} className="btn btn-secondary mr5">
              Cancel and Go Back
            </Link>
            {!selectingTemplate && (
              <button
                type="submit"
                className="btn btn-primary"
                form="compose_campaign_form"
                disabled={!validCampaign}
              >
                Save Campaign
              </button>
            )}
          </div>
          <span className="text-nowrap">Compose a Campaign</span>
        </h4>

        <CampaignTabs
          campaign={campaign}
          campaignSettings={campaignSettings}
          loadingCampaign={loadingCampaign}
          campaignErrors={campaignErrors}
          errors={errors}
          validCampaign={validCampaign}
          selectingTemplate={selectingTemplate}
          onCampaignStateChange={onCampaignStateChange}
          history={history}
          setCampaignErrors={handleSetCampaignErrors}
          setErrors={handleSetErrors}
        />
      </div>
    </div>
  );
};

ComposingCampaign.defaultProps = {
  campaignID: null,
};

ComposingCampaign.propTypes = {
  campaignID: PropTypes.string,
};

export default ComposingCampaign;
