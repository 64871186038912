import autoBind            from 'react-autobind';
import React               from 'react';
import classNames          from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import brokerbit                   from '~/lib/brokerbit';
import APIRequest                  from '~/lib/api_request';
import TwilioProfileStore           from '~/stores/twilio_profile_store';
import TwilioVerifiedTextingActions from '~/actions/twilio_verified_texting_actions';
import TwilioVerifiedTextingStore   from '~/stores/twilio_verified_texting_store';
import ErrorMessage                from '~/components/forms/ErrorMessage';
import VerifiedTextingPlanSelect    from '~/components/forms/verified_texting_plan_select';
import ErrorAlert                  from '~/components/shared/error_alert';
import MessagingServiceItem        from './components/MessagingServiceItem';

const LENGTH_CRITERIA = {
  description: {
    min: process.env.A2P_DESCRIPTION_MIN,
    max: process.env.A2P_DESCRIPTION_MAX,
  },
  message_sample: {
    min: process.env.A2P_MSG_SAMPLE_MIN,
    max: process.env.A2P_MSG_SAMPLE_MAX,
  },
  message_flow: {
    min: process.env.A2P_MESSAGE_FLOW_MIN,
    max: process.env.A2P_MESSAGE_FLOW_MAX,
  },
};
const confirmationMessage = 'Are you sure you want to delete this text use case?';
const deleteConfirmationMessage = `${confirmationMessage} This action cannot be undone.
  You will need to create a new text use case from scratch and will require going through the entire approval process again.`;

class VerifiedTextingTab extends React.Component {
  constructor(props) {
    super(props);

    this._isMounted = false;

    this.emptyMessagingService = {
      id:          null,
      usecase:     'MIXED',
      description:
        'Text notifications with updates to internal team members',
      message_sample_one:
        'Hi Jane, this is a reminder that we have a training webinar on lead follow up tomorrow at 9 am at the link below. Look forward to seeing you there. Betsy https://zoom.us/j/123',
      message_sample_two:
        'Hi Bob, your license needs to be renewed by June 30th. Please confirm you have completed that. Sara',
      has_embedded_links: true,
      has_embedded_phone: false,
      message_flow:
        'Customers opt-in by creating their account with a click through agreement on the website and then add their phone numbers in that account to enable texting',
      sms_numbers: [],
    };

    this.state = {
      loading:       false,
      creating:      false,
      saving:        false,
      submitting:    false,
      twilio_errors: [],
      errors:        {},
      error:         false,
      brand:         {
        id:                 null,
        plan:               '',
        messaging_services: [],
      },
      deletingMessagingServiceIds: [],
    };

    autoBind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    // listen to TwilioProfileStore change events and store reference
    this.profile_listener = TwilioProfileStore.addListener(
      this.onTwilioProfileStoreChange,
    );

    // listen to TwilioVerifiedTextingStore change events and store reference
    this.brand_listener = TwilioVerifiedTextingStore.addListener(
      this.onTwilioVerifiedTextingStoreChange,
    );

    if (this._isMounted) {
      TwilioVerifiedTextingActions.loadVerifiedTexting();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (this.profile_listener) this.profile_listener.remove();
    if (this.brand_listener) this.brand_listener.remove();
  }

  handleAddAppToPersonCampaign(e) {
    e.preventDefault();

    const { brand } = this.state;

    brand.messaging_services.push({ ...this.emptyMessagingService });
    this.setState({ brand });
  }

  handleMessagingServiceDelete(e, messaging_service) {
    e.preventDefault();

    brokerbit.confirmBox({
      message:  messaging_service?.messaging_service_id ? deleteConfirmationMessage : confirmationMessage,
      callback: (ok) => {
        if (ok) {
          if (messaging_service.messaging_service_id) {
            // If the messaging service has been submitted, make an API call to delete it
            this.setState((prevState) => ({
              deletingMessagingServiceIds: [
                ...prevState.deletingMessagingServiceIds,
                messaging_service.messaging_service_id,
              ],
            }));

            APIRequest.delete({
              resource: `/v1/twilio_verified_texting/${messaging_service.messaging_service_id}`,
            }).end((error, response) => {
              if (!error && response.status === 200) {
                this.setState((prevState) => ({
                  brand: {
                    ...prevState.brand,
                    messaging_services: prevState.brand.messaging_services.filter(
                      (ms) => ms.messaging_service_id !== messaging_service.messaging_service_id,
                    ),
                  },
                  deletingMessagingServiceIds: prevState.deletingMessagingServiceIds.filter(
                    (id) => id !== messaging_service.messaging_service_id,
                  ),
                }), () => {
                  GlobalContainer.notify('Texting Use Case Deleted', 'success');
                });
              } else {
                this.setState((prevState) => ({
                  deletingMessagingServiceIds: prevState.deletingMessagingServiceIds.filter(
                    (id) => id !== messaging_service.messaging_service_id,
                  ),
                }), () => {
                  GlobalContainer.notify(
                    'Error Deleting Texting Use Case, Please try again',
                    'error',
                  );
                });
              }
            });
          } else {
            // If the messaging service hasn't been submitted, simply remove it from state
            this.setState((prevState) => ({
              brand: {
                ...prevState.brand,
                messaging_services: prevState.brand.messaging_services.filter(
                  (ms) => ms !== messaging_service,
                ),
              },
            }));
          }
        }
      },
    });
  }

  handleInputChange = (e, messaging_service, field) => {
    e.preventDefault();
    this.updateMessagingService(messaging_service, field, e.target.value);
  };

  handleCheckboxChange = (e, messaging_service, field) => {
    this.updateMessagingService(messaging_service, field, e.target.checked);
  };

  handleTextSegments = (selectedOption) => {
    this.updateBrand(
      'plan',
      selectedOption ? selectedOption.value || selectedOption : '',
    );
  };

  handleUseCaseChange = (opt, messaging_service) => {
    this.updateMessagingService(
      messaging_service,
      'usecase',
      opt ? opt.value : '',
    );
  };

  handlePhoneNumbers = (opt, messaging_service) => {
    this.updateMessagingService(
      messaging_service,
      'sms_numbers',
      opt,
    );
  };

  onTwilioProfileStoreChange() {
    const {
      errors,
      submitting,
      twilio_errors,
      lastTwilioProfileStoreAction,
    } = TwilioProfileStore.getState();

    const newState = {
      errors,
      submitting,
      twilio_errors,
    };

    if (lastTwilioProfileStoreAction === 'submitTwilioProfileDone') {
      GlobalContainer.notify('Your Profile was submitted.');
    } else if (lastTwilioProfileStoreAction === 'submitTwilioProfileFail') {
      newState.submitError = twilio_errors ? this.formatErrors(twilio_errors) : 'Failed to submit profile.';
    }

    if (submitting) newState.submitError = null;
    this.setState(newState);
  }

  onTwilioVerifiedTextingStoreChange() {
    const {
      brand,
      errors,
      error,
      creating,
      saving,
      loading,
      lastTwilioVerifiedTextingStoreAction,
    } = TwilioVerifiedTextingStore.getState();

    const newState = {
      brand,
      errors,
      error,
      creating,
      saving,
      loading,
    };

    if (
      lastTwilioVerifiedTextingStoreAction
        === 'createVerifiedTextingDone'
      || lastTwilioVerifiedTextingStoreAction
        === 'updateVerifiedTextingDone'
    ) {
      GlobalContainer.notify('Your changes was saved.');
    }

    this.setState(newState);
  }

  onCreateClick(e, tabName) {
    e.preventDefault();

    const errors = this.validate();

    const { brand } = this.state;
    const { setTab } = this.props;
    const data = { ...brand };

    if (_lodash.size(errors) === 0) {
      TwilioVerifiedTextingActions.createVerifiedTexting(data);

      if (tabName) {
        setTab(tabName);
      }
    } else {
      this.setState({ errors });
    }
  }

  onSaveClick(e, tabName) {
    e.preventDefault();

    const errors = this.validate();
    const { brand } = this.state;
    const { setTab } = this.props;
    const data = { ...brand };

    if (_lodash.size(errors) === 0) {
      TwilioVerifiedTextingActions.updateVerifiedTexting(data);

      if (tabName) {
        setTab(tabName);
      }
    } else {
      this.setState({ errors });
    }
  }

  onSubmitClick(e) {
    e.preventDefault();

    const errors = this.validate();
    const { brand } = this.state;
    const {
      business_information,
      address,
      authorized_representative_1,
    } = this.props;
    const data = { ...brand };

    if (
      !_lodash.isEmpty(business_information)
      && !_lodash.isEmpty(address)
      && !_lodash.isEmpty(authorized_representative_1)
    ) {
      if (_lodash.size(errors) === 0) {
        if (!brand.id) {
          TwilioVerifiedTextingActions.createVerifiedTexting(
            data,
            'submitTwilioProfile',
          );
        } else {
          TwilioVerifiedTextingActions.updateVerifiedTexting(
            data,
            'submitTwilioProfile',
          );
        }
      } else {
        this.setState({ errors });
      }
    } else {
      GlobalContainer.notify(
        'Your Business Profile Cannot Be Submitted.',
        'error',
      );
    }
  }

  updateBrand = (field, value) => {
    this.setState((prevState) => ({
      brand: {
        ...prevState.brand,
        [field]: value,
      },
    }));
  };

  updateMessagingService = (messaging_service, field, value) => {
    this.setState((prevState) => {
      const newMessagingServices = prevState.brand.messaging_services.map((ms) => {
        if (ms === messaging_service) {
          return {
            ...ms,
            [field]: value,
          };
        }
        return ms;
      });

      return {
        brand: {
          ...prevState.brand,
          messaging_services: newMessagingServices,
        },
      };
    });
  };

  formatErrors = (errorList) => errorList.map((obj) => Object.entries(obj).map(([key, value]) => `${key}: '${value}'`).join(', ')).join(' and ')

  isRecentlyUpdated = (messaging_service) => {
    if (!messaging_service.updated_at) return false;

    const updateTime = new Date(messaging_service.updated_at).getTime();
    const currentTime = new Date().getTime();
    const twoMinutes = 2 * 60 * 1000;

    return (currentTime - updateTime) < twoMinutes;
  };

  validate() {
    const errors = {};
    const { brand } = this.state;
    const messagingServices = brand.messaging_services;

    if (!brand.plan) {
      errors.plan = ["Can't be empty"];
    }

    // if (brand.plan === 'starter') return;

    if (brand.plan === 'standard' || brand.plan === 'starter') {
      if (
        messagingServices.filter((x) => !!x).length < 1
      ) {
        errors.brand = ['Please Add Texting Use Case'];
      }

      messagingServices.forEach((campaign, index) => {
        if (
          _lodash.isNumber(campaign.id)
          && !_lodash.isEmpty(campaign.sid)
        ) {
          return;
        }

        errors[index] = {};

        if (!campaign.usecase) {
          errors[index].usecase = ["Can't be empty"];
        }

        if (!campaign.description) {
          errors[index].description = ["Can't be empty"];
        } else {
          const { min, max } = LENGTH_CRITERIA.description;
          const contentLength = campaign.description.length;
          const inRange =            min <= contentLength && contentLength <= max;

          if (!inRange) {
            errors[index].description = [
              `Content should be from ${min} to ${max} characters long`,
            ];
          }
        }

        if (!campaign.message_sample_one) {
          errors[index].message_sample_one = ["Can't be empty"];
        } else {
          const { min, max } = LENGTH_CRITERIA.message_sample;
          const contentLength = campaign.message_sample_one.length;
          const inRange =            min <= contentLength && contentLength <= max;

          if (!inRange) {
            errors[index].message_sample_one = [
              `Content should be from ${min} to ${max} characters long`,
            ];
          }
        }

        if (!campaign.message_sample_two) {
          errors[index].message_sample_two = ["Can't be empty"];
        } else {
          const { min, max } = LENGTH_CRITERIA.message_sample;
          const contentLength = campaign.message_sample_two.length;
          const inRange =            min <= contentLength && contentLength <= max;

          if (!inRange) {
            errors[index].message_sample_two = [
              `Content should be from ${min} to ${max} characters long`,
            ];
          }
        }

        if (!campaign.message_flow) {
          errors[index].message_flow = ["Can't be empty"];
        } else {
          const { min, max } = LENGTH_CRITERIA.message_flow;
          const contentLength = campaign.message_flow.length;
          const inRange =            min <= contentLength && contentLength <= max;

          if (!inRange) {
            errors[index].message_flow = [
              `Content should be from ${min} to ${max} characters long`,
            ];
          }
        }

        if (_lodash.isEmpty(campaign.sms_numbers)) {
          errors[index].sms_numbers = ["Can't be empty"];
        }

        if (_lodash.size(errors[index]) === 0) delete errors[index];
      });
    }

    return errors;
  }

  renderTwilioErrors() {
    const { brand, twilio_errors } = this.state;

    const evaluation_errors =      (brand.evaluation && brand.evaluation.status_errors) || [];
    const errors = [...twilio_errors, ...evaluation_errors];

    if (
      _lodash.isEmpty(errors)
      || (brand.evaluation && brand.evaluation.status === 'compliant')
    ) {
      return null;
    }

    return (
      <div className="alert alert-danger text-left mb-3" role="alert">
        <ol>
          {errors.map((item, index) => (
            <li key={index}>
              {Object.keys(item)}
              <ul>
                {_lodash.flatten(Object.values(item)).map((v, i) => (
                  <li key={i}>{v}</li>
                ))}
              </ul>
            </li>
          ))}
        </ol>
      </div>
    );
  }

  renderCampaignStatus(campaign) {
    const status = campaign ? campaign.status_code : 'draft';

    let text = '';
    let icon = '';

    switch (status) {
      case 'IN_PROGRESS':
        text = 'In Review';
        icon = (
          <FontAwesomeIcon
            icon={['fas', 'fa-exclamation-circle']}
            className="text-secondary"
          />
        );
        break;
      case 'VERIFIED':
        text = 'Approved';
        icon = (
          <FontAwesomeIcon
            icon={['fas', 'fa-check-circle']}
            className="text-success"
          />
        );
        break;
      case 'FAILED':
        text = 'Rejected';
        icon = (
          <FontAwesomeIcon
            icon={['fas', 'fa-exclamation-circle']}
            className="text-danger"
          />
        );
        break;
      default:
        text = 'Draft';
        icon = (
          <FontAwesomeIcon
            icon={['fas', 'fa-exclamation-circle']}
            className="text-secondary"
          />
        );
        break;
    }

    return (
      <span>
        -
        {' '}
        {text}
        {' '}
        {icon && icon}
      </span>
    );
  }

  renderMessagingService() {
    const { brand, errors, deletingMessagingServiceIds } = this.state;
    const messagingServiceAttributes = brand.messaging_services;

    return messagingServiceAttributes.map(
      (messaging_service, index) => {
        const isDeleting = messaging_service.messaging_service_id
          ? deletingMessagingServiceIds.includes(messaging_service.messaging_service_id)
          : false;
        const disableStatus = Boolean(
          (messaging_service.id && messaging_service.sid)
          || this.isRecentlyUpdated(messaging_service),
        );

        return (
          <MessagingServiceItem
            key={messaging_service.id || `new-${index}`}
            messaging_service={messaging_service}
            index={index}
            counter={index + 1}
            errors={errors}
            isDeleting={isDeleting}
            disableStatus={disableStatus}
            onUseCaseChange={this.handleUseCaseChange}
            onInputChange={this.handleInputChange}
            onCheckboxChange={this.handleCheckboxChange}
            onPhoneNumbersChange={this.handlePhoneNumbers}
            onDelete={this.handleMessagingServiceDelete}
            renderCampaignStatus={this.renderCampaignStatus}
          />
        );
      },
    );
  }

  renderStandardPlan() {
    const { errors, deletingMessagingServiceIds } = this.state;
    const isDeleting = deletingMessagingServiceIds.length > 0;

    return (
      <>
        <div className="col-12 mt-3 p0">
          <button
            type="button"
            className="btn btn-success"
            onClick={this.handleAddAppToPersonCampaign}
            disabled={isDeleting}
          >
            <FontAwesomeIcon icon={['far', 'fa-plus']} />
            {' '}
            Add Texting Use Case
          </button>
          <div className="mt-2">
            {errors.brand && <ErrorMessage message={errors.brand} />}
          </div>
        </div>
        {this.renderMessagingService()}
      </>
    );
  }

  renderVerifiedTextingPlanText() {
    const { brand } = this.state;
    const { plan } = brand;

    if (!plan) return null;

    return (
      <p className="text-left text-gray-33 text-sm">
        You will be submitting this information to the carriers so
        they understand what type of messages you&apos;ll be sending.
        {' '}
        Declaring your exact campaign use case rather than using a
        mixed campaign use case will generally result in higher limits
        because you&apos;re being more specific about what messages
        you are sending.
      </p>
    );
  }

  render() {
    const {
      brand,
      loading,
      submitting,
      creating,
      saving,
      errors,
      error,
      submitError,
      deletingMessagingServiceIds,
    } = this.state;
    const {
      business_information,
      address,
      authorized_representative_1,
    } = this.props;

    const disableStatus = false; // status.status_code === 'in-review' || (shaken_stir && shaken_stir.status_code === 'in-review') || (brand && brand.status_code === 'PENDING');
    const disableSubmitButton = !_lodash.isEmpty(business_information)
      && !_lodash.isEmpty(address)
      && !_lodash.isEmpty(authorized_representative_1)
      && deletingMessagingServiceIds.length === 0;

    if (loading) {
      return (
        <div className="text-center">
          <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
        </div>
      );
    }

    if (error) {
      return (
        <div className="text-center">
          Oops, something went wrong. Try again later.
        </div>
      );
    }

    return (
      <>
        {!submitError && this.renderTwilioErrors()}

        <p className="text-16 font-weight-med mb-3">
          How many text segments do you plan to send per day and how
          many phone numbers do you plan to text from?
        </p>

        <form>
          <div className="row text-center">
            <div className="form-group col">
              <VerifiedTextingPlanSelect
                placeholder="Select an option"
                clearable
                onChange={(opt) => this.handleTextSegments(opt)}
                value={brand.plan}
                className={classNames({ 'has-error': !!errors.plan })}
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-12">
              {this.renderVerifiedTextingPlanText()}

              {(brand.plan === 'standard'
                || brand.plan === 'starter')
                && this.renderStandardPlan()}
            </div>
          </div>

          <div className="row">
            <div className="col-md-8 pr-0">
              {submitError && (
                <ErrorAlert
                  heading="Oops, Unable to Submit Verified Texting Profile"
                  message={submitError}
                />
              )}
            </div>
            <div className="col-md-4 pl-0 pr-2">
              <div className="pull-right">
                {brand.id === null || brand.id === undefined ? (
                  <>
                    <button
                      type="button"
                      disabled={creating || disableStatus}
                      className={classNames('btn btn-success mr-2', {
                        disabled: creating || disableStatus,
                      })}
                      onClick={(e) => {
                        this.onCreateClick(e, 'business_contacts');
                      }}
                    >
                      Back
                    </button>

                    {creating ? (
                      <button
                        type="button"
                        className="btn btn-success mr-2 disabled"
                        disabled={creating}
                      >
                        <FontAwesomeIcon
                          icon="far fa-spinner"
                          pulse
                          className="mr5"
                        />
                        {' '}
                        Saving ...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-success mr-2"
                        onClick={this.onCreateClick}
                      >
                        Save
                      </button>
                    )}
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className={classNames('btn btn-success mr-2', {
                        disabled: saving || disableStatus,
                      })}
                      disabled={saving || disableStatus}
                      onClick={(e) => {
                        this.onSaveClick(e, 'business_contacts');
                      }}
                    >
                      Back
                    </button>

                    {saving ? (
                      <button
                        type="button"
                        className="btn btn-success mr-2 disabled px-2"
                        disabled={saving}
                      >
                        <FontAwesomeIcon
                          icon="far fa-spinner"
                          pulse
                          className="mr5"
                        />
                        {' '}
                        Saving ...
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={this.onSaveClick}
                        className={classNames('btn btn-success mr-2', {
                          disabled: disableStatus,
                        })}
                        disabled={disableStatus}
                      >
                        Save
                      </button>
                    )}
                  </>
                )}

                {submitting ? (
                  <button
                    type="button"
                    className="btn btn-success disabled px-1"
                    disabled={submitting}
                  >
                    <FontAwesomeIcon
                      icon="far fa-spinner"
                      pulse
                      className="mr5"
                    />
                    {' '}
                    Submitting ...
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={this.onSubmitClick}
                    className={classNames('btn btn-success', {
                      disabled: !disableSubmitButton || disableStatus,
                    })}
                    disabled={!disableSubmitButton || disableStatus}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}

VerifiedTextingTab.defaultProps = {};

VerifiedTextingTab.propTypes = {};

export default VerifiedTextingTab;
