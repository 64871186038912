import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { PropTypes } from 'prop-types';
import { Tooltip } from 'reactstrap';

const Tooltipable = ({
  text,
  trigger,
  placement = 'bottom',
  className = '',
  innerClassName = '',
  tooltipContentClassName = '',
  target: targetProp,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [id] = useState(() => `tooltip-${uuidv4()}`);
  const toggle = () => setIsOpen(!isOpen);
  const target = targetProp || id;

  return (
    <span id={target} className={className}>
      <span>
        {children}
      </span>

      {text && (
        <Tooltip
          trigger={trigger}
          placement={placement}
          isOpen={isOpen}
          target={target}
          autohide={false}
          innerClassName={innerClassName}
          toggle={toggle}
        >
          <div className={tooltipContentClassName} dangerouslySetInnerHTML={{ __html: text }} />
        </Tooltip>
      )}
    </span>
  );
};

Tooltipable.propTypes = {
  trigger:                 PropTypes.string,
  text:                    PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  placement:               PropTypes.string,
  className:               PropTypes.string,
  tooltipContentClassName: PropTypes.string,
  target:                  PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  innerClassName:          PropTypes.string,
  children:                PropTypes.node,
};

Tooltipable.defaultProps = {
  trigger:                 'hover',
  placement:               'bottom',
  className:               'd-inline-block',
  tooltipContentClassName: '',
  innerClassName:          '',
  target:                  undefined,
  children:                undefined,
};

export default Tooltipable;
