import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import APIRequest         from '~/lib/api_request';
import TeamMembersActions from '~/actions/team_members_actions';
import TeamMembersStore   from '~/stores/team_members_store';
import TeamMembersTab     from './team_members_tab';
import TeamMembersSearch  from './team_members_search';
import TeamMembersTable   from './team_members_table';
import { TeammatePicker } from '~/components/forms/team_member_picker';
import Tooltipable from '~/components/effects/tooltipable';

const accountPointOfContactToolTipText = `Select the Brokerkit user who manages this account's day-to-day operations and communications. This contact will be the first point of reference for account-related matters.<br />
<br/>
Note: This is separate from the billing email address (set on the billing page <a href="/billing" class="text-green">here</a>), where invoices and billing-related communications are sent.`;

class TeamMembersCore extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    const tabs = props.tabs || ['admins', 'staff', 'agents', 'affiliates'];
    const { currentTeam } = Rails.helpers;
    this.state = {
      selectedTab:     props.selectedTab || tabs[0],
      searchQuery:     '',
      currentPage:     1,
      tabs,
      account_poc_id:  currentTeam.account_poc_id || null,
    };
  }

  getChildContext() {
    const { mode } = this.props;
    const { selectedTab, searchQuery, currentPage } = this.state;

    return {
      selectedTab,
      searchQuery,
      currentPage,
      mode,
    };
  }

  componentDidMount() {
    const { per, filter } = this.props;

    this.teamMembersStoreListener = TeamMembersStore.addListener(this.onTeamMembersStoreChange);

    TeamMembersActions.loadTeamMembers(this.state.selectedTab, { page: 1, per, filter });
  }

  componentWillUnmount() {
    if (this.teamMembersStoreListener) this.teamMembersStoreListener.remove();
  }

  onTeamMembersStoreChange() {
    const teamMembersState = TeamMembersStore.getState();

    this.props.onTeamMembersStoreChange(teamMembersState);

    this.setState({
      teamMembersState,
    });
  }

  handleTabSwitch(role) {
    const { per, filter } = this.props;

    TeamMembersActions.loadTeamMembers(role, {
      q: this.state.searchQuery, page: 1, per, filter,
    });

    this.setState({
      selectedTab: role,
      currentPage: 1,
    });

    if (this.props.handleTabSwitch) this.props.handleTabSwitch(role);
  }

  handleSearch(query) {
    const { per, filter } = this.props;

    this.setState({
      searchQuery: query,
      selectedTab: query.length > 0 ? 'members' : this.state.selectedTab,
      currentPage: 1,
    });

    TeamMembersActions.loadTeamMembers(this.state.selectedTab, {
      q: query, page: 1, per, filter,
    });
  }

  handleAccountPocChange(selectedOption) {
    const newAccountPocId = selectedOption ? selectedOption?.user?.id : null;

    if (!newAccountPocId) {
      return;
    }

    this.setState({ account_poc_id: newAccountPocId });

    APIRequest.put({
      resource: `/v1/teams/${Rails.helpers.currentTeam.id}`,
      data:     {
        team: {
          account_poc_id: newAccountPocId,
        },
      },
    })
      .then((response) => {
        if (response.ok || response.status === 200) {
          GlobalContainer.notify('Account POC has been updated successfully', 'success');
        } else {
          GlobalContainer.notify('Failed to update Account POC', 'error');
        }
      })
      .catch((error) => {
        console.error('Error updating Account POC:', error);
      });
  }

  clearSearch() {
    const { per, filter } = this.props;

    this.setState({
      searchQuery: '',
      currentPage: 1,
    });

    TeamMembersActions.loadTeamMembers(this.state.selectedTab, { page: 1, per, filter });
  }

  handlePageChange(page) {
    const { per, filter } = this.props;
    const { selectedTab, searchQuery } = this.state;

    this.setState({
      currentPage: page + 1,
    });

    TeamMembersActions.loadTeamMembers(selectedTab, {
      q: searchQuery, page: page + 1, per, filter,
    });
  }

  render() {
    const { currentUser } = Rails.helpers;
    const { title, notify, drawerExternalRolesOnly } = this.props;
    const {
      selectedTab, searchQuery, currentPage, teamMembersState, tabs, account_poc_id,
    } = this.state;
    const disableAccountPoc = !(currentUser.role.name === 'admin' || currentUser.admin);

    return (
      <div className="card">
        <div className="card-block">
          <h4 className="clearfix">
            {title}

            <div className="pull-right">
              <TeamMembersSearch
                memberRole={selectedTab}
                searchQuery={searchQuery}
                currentPage={currentPage}
                handleSearch={this.handleSearch}
                notify={notify}
                drawerExternalRolesOnly={drawerExternalRolesOnly}
              />
            </div>
          </h4>

          <div className="d-flex flex-fill">
            <div>
              <label htmlFor="team-account-poc-id">Account Point of Contact</label>
              {' '}
              <Tooltipable
                text={accountPointOfContactToolTipText}
                trigger="click"
                placement="right"
              >
                <FontAwesomeIcon icon={['fal', 'fa-info-circle']} size="lg" style={{ fontSize: '.7em' }} className="text-secondary" />
              </Tooltipable>

              <div className="mb15">
                <TeammatePicker
                  id="team-account-poc-id"
                  name="team-account-poc-id"
                  value={account_poc_id}
                  onChange={(opt) => {
                    this.handleAccountPocChange(opt);
                  }}
                  disabled={disableAccountPoc}
                />
              </div>
            </div>
          </div>

          <TeamMembersTab
            tabs={tabs}
            memberRole={selectedTab}
            handleTabSwitch={this.handleTabSwitch}
          />
        </div>
        <TeamMembersTable
          memberRole={selectedTab}
          {...teamMembersState}
          handlePageChange={this.handlePageChange}
          notify={notify}
          searchQuery={searchQuery}
          clearSearch={this.clearSearch}
          drawerExternalRolesOnly={drawerExternalRolesOnly}
        />
      </div>
    );
  }
}

TeamMembersCore.childContextTypes = {
  selectedTab: PropTypes.string,
  searchQuery: PropTypes.string,
  currentPage: PropTypes.number,
  mode:        PropTypes.string,
};

TeamMembersCore.defaultProps = {
  title:                    'Manage Team',
  sidebar:                  true,
  per:                      10,
  filter:                   [],
  notify:                   true,
  drawerExternalRolesOnly:  false,
  handleTabSwitch:          () => false,
  onTeamMembersStoreChange: () => false,
  mode:                     'default',
};

TeamMembersCore.propTypes = {
  title:                    PropTypes.string,
  tabs:                     PropTypes.arrayOf(PropTypes.string),
  selectedTab:              PropTypes.string,
  sidebar:                  PropTypes.bool,
  per:                      PropTypes.number,
  filter:                   PropTypes.arrayOf(PropTypes.string),
  mode:                     PropTypes.string,
  notify:                   PropTypes.bool,
  drawerExternalRolesOnly:  PropTypes.bool,
  handleTabSwitch:          PropTypes.func,
  onTeamMembersStoreChange: PropTypes.func,
};

export default TeamMembersCore;
