const EditorHelpers = {
  addVisitLink: (editor) => {
    editor.on('OpenWindow', (e) => {
      const dialogHeaderSelector = 'div[role="dialog"] .tox-dialog__header';
      const titleElement = document.querySelector(`${dialogHeaderSelector} .tox-dialog__title`);
      if (titleElement?.textContent == 'Insert/Edit Link') {
        const url = e.dialog.getData().url?.value;
        if (!url) return;

        const visitLinkButtonHtml = `<a href=${url} target="_blank" class="btn btn-link" style="right: 60px; position: absolute;"> Visit Link <i class="far fa-external-link-alt"></i></a>`;
        const closeButton = document.querySelector(`${dialogHeaderSelector} button[data-mce-name="close"].tox-button`);
        closeButton.insertAdjacentHTML('beforebegin', visitLinkButtonHtml);
      }
    });
  },
};

export default EditorHelpers;
