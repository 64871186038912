import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { PropTypes }        from 'prop-types';
import qs from 'qs';
import classNames           from 'classnames';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';

import GlobalContainer from '~/components/global_container';
import CampaignActions from '~/actions/campaign_actions';
import Tooltipable     from '~/components/effects/tooltipable';

const CampaignItem = ({
  campaign: initialCampaign,
  handleCampaignClick,
  handleCampaignDelete,
  handleCampaignDonate,
}) => {
  const history = useHistory();
  const [settingStatus, setSettingStatus] = useState(false);
  const [campaign, setCampaign] = useState(initialCampaign);

  const handleSearch = (searchData) => {
    const searchQuery = qs.stringify(
      { s: searchData, _t: Rails.helpers.currentTeam.id },
      { arrayFormat: 'brackets' },
    );
    const resource = GlobalContainer.product() === 'retention' ? 'retention' : 'recruiter';

    history.push(`/${resource}/search?${decodeURI(searchQuery)}`, searchData);
  };

  const onStatusChange = (updatedCampaign) => {
    setSettingStatus(false);
    setCampaign(updatedCampaign);
  };

  const handlePlayClick = (e) => {
    e.preventDefault();

    CampaignActions.runCampaign(campaign.id, onStatusChange);
    setSettingStatus(true);
  };

  const handlePauseClick = (e) => {
    e.preventDefault();

    CampaignActions.pauseCampaign(campaign.id, onStatusChange);
    setSettingStatus(true);
  };

  const renderStatusControls = () => {
    const buttonClass = classNames('btn btn-secondary btn-sm mr5', { disabled: settingStatus });

    if (campaign.status === 'running') {
      return (
        <Tooltipable text="Pauses campaign for all subscribers">
          <button
            type="button"
            className={buttonClass}
            disabled={settingStatus}
            onClick={handlePauseClick}
          >
            <FontAwesomeIcon icon={['fas', 'pause']} />
          </button>
        </Tooltipable>
      );
    }

    return (
      <Tooltipable text="Runs campaign for all subscribers">
        <button
          type="button"
          className={buttonClass}
          disabled={settingStatus}
          onClick={handlePlayClick}
        >
          <FontAwesomeIcon icon={['fas', 'play']} />
        </button>
      </Tooltipable>
    );
  };

  let { duration } = campaign;
  if (duration < 86400) duration = 86400;
  else duration += 86400;

  const durationMessage = `lasts ${Moment().add(duration, 'seconds').fromNow(true)}`;
  const campaignEditUri = GlobalContainer.productUri(`/campaigns/${campaign.id}/edit`);

  return (
    <tr
      key={`campaign-${campaign.id}`}
      className="text-grey"
      onClick={(e) => {
        e.preventDefault();
        if (e.target.nodeName === 'TD') {
          handleCampaignClick(campaign);
        }
      }}
    >
      <td className="col">
        <strong className="text-wrap">
          <Link to={campaignEditUri} className="text-grey-dark text-no-underline">
            {campaign.name}
          </Link>
        </strong>

        <p className="text-wrap">
          <span className="text-grey text-no-underline campaign-desc">
            {campaign.description}
          </span>
        </p>

        <p className="mt10">
          <small className="text-grey">
            <FontAwesomeIcon icon={['far', 'bullhorn']} className="mr5" />
            {` ${campaign.campaign_steps_count} emails/texts/tasks`}
          </small>
          <small className="text-grey ml30">
            {campaign.new_engine ? (
              <Tooltipable text="This field specifies the duration of the campaign and includes only the waiting periods between steps and does not factor in the On and At parameters as those will depend on the start date.">
                <>
                  <FontAwesomeIcon icon={['far', 'clock']} className="mr5" />
                  {durationMessage}
                </>
              </Tooltipable>
            ) : (
              <>
                <FontAwesomeIcon icon={['far', 'clock']} className="mr5" />
                {durationMessage}
              </>
            )}
          </small>

          <small className="text-grey ml30">
            <FontAwesomeIcon icon={['far', 'users']} className="mr5" />
            {` ${campaign.in_progress_subscriptions_count} subscribed`}
          </small>

          <small className="text-grey ml30">
            <FontAwesomeIcon icon={['far', 'check']} className="mr5" />
            {` ${campaign.completed_subscriptions_count} completed`}
          </small>

          <small className="text-grey ml30">
            <a
              href="#search"
              className="text-grey text-no-underline"
              onClick={(e) => {
                e.preventDefault();
                handleSearch({
                  query:    '',
                  campaign: [campaign.id],
                });
              }}
            >
              <FontAwesomeIcon icon={['far', 'paper-plane']} className="mr5" />
              view subscribers
            </a>
          </small>
        </p>
      </td>
      <td className="col-2">
        <span className="pull-right">
          {campaign.new_engine && (
            <span className="badge badge-success mr5 p-2">2.0</span>
          )}

          <Tooltipable text="Donate campaign to Campaign Library">
            <button
              type="button"
              className="btn btn-secondary btn-sm mr5"
              onClick={(e) => {
                e.preventDefault();
                handleCampaignDonate(campaign);
              }}
            >
              <FontAwesomeIcon icon={['far', 'upload']} />
            </button>
          </Tooltipable>

          {renderStatusControls()}

          <Tooltipable text="Edit campaign">
            <button
              type="button"
              className="btn btn-secondary btn-sm mr5"
              onClick={(e) => {
                e.preventDefault();
                handleCampaignClick(campaign);
              }}
            >
              <FontAwesomeIcon icon={['far', 'edit']} />
            </button>
          </Tooltipable>

          <Tooltipable text="Deletes campaign and all subscriptions">
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={(e) => {
                e.preventDefault();
                handleCampaignDelete(campaign);
              }}
            >
              <FontAwesomeIcon icon={['far', 'trash-alt']} />
            </button>
          </Tooltipable>
        </span>
      </td>
    </tr>
  );
};

CampaignItem.defaultProps = {
  handleCampaignClick:  () => false,
  handleCampaignDelete: () => false,
  handleCampaignDonate: () => false,
};

CampaignItem.propTypes = {
  campaign: PropTypes.shape({
    id:                              PropTypes.number.isRequired,
    name:                            PropTypes.string.isRequired,
    description:                     PropTypes.string,
    status:                          PropTypes.string.isRequired,
    campaign_steps_count:            PropTypes.number,
    in_progress_subscriptions_count: PropTypes.number,
    completed_subscriptions_count:   PropTypes.number,
    duration:                        PropTypes.number,
    new_engine:                      PropTypes.bool,
  }).isRequired,
  handleCampaignClick:  PropTypes.func,
  handleCampaignDelete: PropTypes.func,
  handleCampaignDonate: PropTypes.func,
};

export default CampaignItem;
