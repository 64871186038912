import autoBind from 'react-autobind';
import classNames from 'classnames';
import update from 'immutability-helper';
import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import GlobalContainer from '~/components/global_container';
import CampaignActions from '~/actions/campaign_actions';
import CampaignStep from './campaign_form/campaign_step';
import CampaignStepNew from './campaign_form/campaign_step_new';
import CampaignStepTemplate from './campaign_form/campaign_step_template';
import ErrorMessageWithIcon from '~/components/forms/ErrorMessageWithIcon';
import Tooltipable from '~/components/effects/tooltipable';

const charsLimit = 1600;
const { newCampaignEngineFeatureEnabled } = Rails.helpers;

class CampaignForm extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);

    this.state = {
      stepsErrors:       {},
      submitting:        false,
      errors:            {},
      campaignForm:      {
        id:                        null,
        name:                      '',
        description:               '',
        new_engine:                true,
        campaign_steps_attributes: [],
        pause_on_text_reply:       false,
        pause_on_email_reply:      false,
      },
    };

    this.stepInstances = [];
  }

  componentDidUpdate(prevProps) {
    const { campaignForm } = this.state;
    const { campaign, campaignSettings } = this.props;
    const updates = {};

    if (prevProps.campaign !== campaign && campaign) {
      const newCampaignForm = this.buildCampaignFormState(campaign);
      Object.keys(newCampaignForm).forEach((key) => {
        if (campaignForm[key] !== newCampaignForm[key]) {
          updates[key] = newCampaignForm[key];
        }
      });
    }

    if (prevProps.campaignSettings !== campaignSettings && campaignSettings) {
      const { pause_on_text_reply, pause_on_email_reply } = campaignSettings;

      if (campaignForm.pause_on_text_reply !== pause_on_text_reply) {
        updates.pause_on_text_reply = pause_on_text_reply;
      }
      if (campaignForm.pause_on_email_reply !== pause_on_email_reply) {
        updates.pause_on_email_reply = pause_on_email_reply;
      }
    }

    if (Object.keys(updates).length > 0) {
      this.setState((prevState) => ({
        campaignForm: {
          ...prevState.campaignForm,
          ...updates,
        },
      }));
    }
  }

  handleNameChange(e) {
    e.preventDefault();

    this.setState((prevState) => ({
      campaignForm: {
        ...prevState.campaignForm,
        name: e.target.value,
      },
    }));
  }

  handleDescriptionChange(e) {
    e.preventDefault();

    this.setState((prevState) => ({
      campaignForm: {
        ...prevState.campaignForm,
        description: e.target.value,
      },
    }));
  }

  handleEngineChange(e) {
    this.setState((prevState) => ({
      campaignForm: {
        ...prevState.campaignForm,
        new_engine: e.target.checked,
      },
    }));
  }

  handleFormSubmit(e) {
    e.preventDefault();

    const { history } = this.props;
    const { campaignForm } = this.state;

    const isSaved = this.saveCampaign(campaignForm);
    const campaigns_uri = GlobalContainer.productUri('/campaigns');

    if (isSaved) {
      GlobalContainer.notify('Campaign saved.', 'success');
      history.push(campaigns_uri);
      window.scrollTo(0, 0);
    }
  }

  handleFromScratchClick() {
    const { onCampaignStateChange } = this.props;

    onCampaignStateChange({
      selectingTemplate: false,
    });
  }

  handleCampaignTemplateClick(templateCampaign) {
    const { onCampaignStateChange } = this.props;

    const newStepAttributes = templateCampaign.campaign_steps.map(
      (step) => {
        // Step without id and campaign ID
        const { id, campaign_id, ...newStep } = step;

        return newStep;
      },
    );

    onCampaignStateChange({ selectingTemplate: false });

    const newState = update(this.state, {
      campaignForm: {
        campaign_steps_attributes: {
          $push: newStepAttributes,
        },
      },
    });

    this.setState(newState);
  }

  buildCampaignFormState(campaignProp) {
    if (!campaignProp) return null;

    return {
      id:                        campaignProp.id,
      name:                      campaignProp.name,
      description:               campaignProp.description,
      new_engine:                campaignProp.new_engine,
      campaign_steps_attributes: campaignProp.campaign_steps,
      pause_on_text_reply:       campaignProp.pause_on_text_reply,
      pause_on_email_reply:      campaignProp.pause_on_email_reply,
    };
  }

  rebuildStepOrder(steps) {
    const { campaignForm } = this.state;
    let position = 1;

    steps.forEach((step, idx) => {
      // intentionally mutate step position
      if (step._destroy === true) {
        step.position = null;
        return;
      }

      // intentionally mutate wait_time to zero if this is first step and old engine - this could be dangerous
      if (position === 1 && !campaignForm.new_engine) step.wait_time = 0;

      step.position = position;
      position += 1;
    });
  }

  addStep(stepForm = 'email', stepIdx) {
    const { campaignForm } = this.state;
    const { campaign_steps_attributes } = campaignForm;

    let defaultOnValue = 'mon-fri';
    let defaultAtValue = '09:00-12:00';
    let defaultWaitTime = 0;

    if (campaignForm.id && campaignForm.new_engine) {
      defaultOnValue = '*';
    }

    if (
      !campaignForm.id
      && campaignForm.new_engine
      && stepForm === 'task'
    ) {
      defaultAtValue = '09:00';
    }

    if (campaignForm.campaign_steps_attributes.length > 0) {
      defaultWaitTime = 86400 * 7; // 7 days
    }

    const newStep = {
      id:                           null,
      position:                     stepIdx + 1,
      action_type:                  stepForm,
      wait_time:                    defaultWaitTime,
      from:                         null,
      subject:                      '',
      body:                         '',
      cc_emails:                    '',
      attachment_ids:               [],
      at:                           defaultAtValue,
      on:                           defaultOnValue,
      uuid:                         uuidv4(),
    };

    if (stepForm === 'task') {
      newStep.task_name = 'Follow Up';
      newStep.task_category = 'follow_up';
      newStep.body = '';
      newStep.task_due_days = 0;
      newStep.task_owner = Rails.helpers.currentUser.id;
      newStep.task_priority = 'normal';
      newStep.task_status = 'to_do';
      newStep.notification_task_assignment = true;
    }

    campaign_steps_attributes.splice(newStep.position, 0, newStep);

    this.rebuildStepOrder(campaign_steps_attributes);

    this.setState({
      campaignForm: {
        ...campaignForm,
        campaign_steps_attributes,
      },
    });
  }

  updateStep(stepIdx, stepDataFragment) {
    const { campaignForm } = this.state;
    const updateStep       = campaignForm.campaign_steps_attributes[stepIdx];

    const newState = update(this.state, {
      campaignForm: {
        campaign_steps_attributes: {
          $splice: [[stepIdx, 1, { ...updateStep, ...stepDataFragment }]],
        },
      },
    });

    this.setState(newState, () => {
      this.validateSmsBody(stepIdx);
    });
  }

  validateSmsBody(stepIdx) {
    const { campaignForm } = this.state;
    const { onCampaignStateChange } = this.props;

    const stepsErrors = {};

    campaignForm.campaign_steps_attributes.forEach((stepAttributes, idx) => {
      if (stepAttributes._destroy === true) return;

      if (stepAttributes.action_type === 'sms' && stepAttributes.body && charsLimit < stepAttributes.body.length) {
        stepsErrors.body = 'You have exceeded your limit of maximum characters allowed';
      }
    });

    this.setState({ stepsErrors });
    onCampaignStateChange({ validCampaign: _lodash.isEmpty(stepsErrors) });
  }

  moveStep(dragIndex, hoverIndex) {
    const { campaignForm } = this.state;
    const dragStep = campaignForm.campaign_steps_attributes[dragIndex];

    const newState = update(this.state, {
      campaignForm: {
        campaign_steps_attributes: {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragStep]],
        },
      },
    });

    this.rebuildStepOrder(newState.campaignForm.campaign_steps_attributes);

    this.setState(newState);
  }

  deleteStep(stepIdx) {
    const { campaignForm } = this.state;
    const deleteStep = campaignForm.campaign_steps_attributes[stepIdx];

    const newState = update(this.state, {
      campaignForm: {
        campaign_steps_attributes: {
          $splice: [[stepIdx, 1, { ...deleteStep, _destroy: true }]],
        },
      },
    });

    this.rebuildStepOrder(newState.campaignForm.campaign_steps_attributes);

    this.setState(newState, () => {
      this.stepInstances = this.stepInstances.filter((el) => el.id !== stepIdx);
    });
  }

  validate(campaignForm) {
    const { name, description } = campaignForm;
    const { setCampaignErrors, setErrors } = this.props;
    const context = this;

    const errors = {};

    if (!name) {
      errors.name = "Can't be empty";
    }

    const campaignStepAttibutes = campaignForm.campaign_steps_attributes.filter((x, i) => !x._destroy);

    if (campaignStepAttibutes.length === 0) {
      errors.steps = "Can't be empty";
    }

    if (description && description.length > 400) {
      errors.description = 'Description must be at most 400 characters';
    }

    campaignStepAttibutes.forEach((stepAttributes, idx) => {
      if (stepAttributes.action_type === 'email' || stepAttributes.action_type === 'sms') {
        if (!stepAttributes.from) {
          errors.step_from = "Can't be empty";
        }

        if (stepAttributes.action_type === 'email' && !stepAttributes.subject) {
          errors.step_subject = "Can't be empty";
        }

        if (!stepAttributes.body) {
          errors.step_body = "Can't be empty";
        }
      }

      if (stepAttributes.action_type === 'task') {
        if (!stepAttributes.task_owner) {
          errors.step_task_owner = "Can't be empty";
        }
      }

      if (stepAttributes.action_type === 'sms' && stepAttributes.body && charsLimit < stepAttributes.body.length) {
        errors.body = 'You have exceeded your limit of maximum characters allowed';
      }
    });

    this.stepInstances.map((stepInstance) => {
      const { instance } = stepInstance;
      const instanceError = instance.validate();

      if (_lodash.size(instanceError) !== 0) {
        errors.instance_error = instanceError;
      }

      return null;
    });

    this.setState({ errors }, () => {
      if (setCampaignErrors) {
        setCampaignErrors(context.renderErrors());
      }

      if (setErrors) {
        setErrors(errors);
      }
    });

    return errors;
  }

  saveCampaign(campaignForm) {
    const errors = this.validate(campaignForm);

    if (_lodash.size(errors) === 0) {
      if (_lodash.isNumber(campaignForm.id)) {
        CampaignActions.updateCampaign(campaignForm);
      } else {
        CampaignActions.createCampaign(campaignForm);
      }

      return true;
    }

    return false;
  }

  renderErrors() {
    const { errors } = this.state;
    const content = [];

    if (errors.steps) {
      content.push(
        <div key={uuidv4()} className="alert alert-danger text-left mb15">
          Can&apos;t save campaign without step.
        </div>,
      );
    }

    if (errors.step_from || errors.step_subject || errors.step_body) {
      content.push(
        <div key={uuidv4()} className="alert alert-danger text-left mb15">
          Fields &quot;From&quot;, &quot;Body&quot; and &quot;Subject&quot; fields are required for Email/Text step.
        </div>,
      );
    }

    if (errors.step_task_owner) {
      content.push(
        <div key={uuidv4()} className="alert alert-danger text-left mb15">
          Field &quot;Task Owner&quot; is required for Task step.
        </div>,
      );
    }

    if (errors?.instance_error?.unverified_number) {
      content.push(
        <div key={uuidv4()} className="alert alert-warning text-left mb15">
          Your campaign includes a text step, but the sender&apos;s phone number is not linked to an approved verified texting use case.
          {' '}
          To save this campaign, please select a phone number in the text step(s) which are linked to an approved verified texting use case.
        </div>,
      );
    }

    return <>{content}</>;
  }

  renderCampaignSteps() {
    const { campaignForm } = this.state;
    const stepAttributes = campaignForm.campaign_steps_attributes;

    return stepAttributes.map((step, idx) => {
      if (step._destroy === true) return false;

      return (
        <CampaignStep
          key={step.id || step.uuid}
          step={step}
          position={step.position}
          stepIdx={idx}
          addStep={this.addStep}
          moveStep={this.moveStep}
          updateStep={this.updateStep}
          deleteStep={this.deleteStep}
          newEngine={campaignForm.new_engine}
          stepAttributes={stepAttributes}
        />
      );
    });
  }

  render() {
    const { campaignForm, errors } = this.state;
    const { validCampaign, selectingTemplate } = this.props;
    const stepsCount = campaignForm.campaign_steps_attributes.filter((x, i) => !x._destroy).length;
    const campaigns_uri = GlobalContainer.productUri('/campaigns');
    const { currentUser } = Rails.helpers;

    return (
      <form
        method="POST"
        action={campaigns_uri}
        id="compose_campaign_form"
        onSubmit={this.handleFormSubmit}
      >
        {(currentUser.admin || newCampaignEngineFeatureEnabled) && (
          <div className="form-group">
            <Tooltipable
              placement="bottom"
              text="Ready to supercharge your campaign? Activate campaigns 2.0 on this campaign now and unlock more flexible scheduling options for your campaign steps. Enjoy the freedom to plan your campaign precisely as per your needs."
            >
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="campaign_engine"
                  onChange={(e) => this.handleEngineChange(e)}
                  checked={campaignForm.new_engine}
                />
                <label
                  className="form-check-label"
                  htmlFor="campaign_engine"
                >
                  {campaignForm.new_engine ? 'Disable' : 'Enable'}
                  {' '}
                  Campaigns 2.0
                </label>
              </div>
            </Tooltipable>
          </div>
        )}

        <div className="form-group">
          <label htmlFor="campaign_name">Campaign Name</label>
          <input
            id="campaign_name"
            name="campaign[name]"
            className={classNames('form-control', {
              'has-error': errors.name,
            })}
            value={campaignForm.name}
            placeholder="e.g. 6 Minute Mega"
            onChange={this.handleNameChange}
          />

          {errors.name && (
            <div key={uuidv4()}>
              <ErrorMessageWithIcon message={[errors.name]} />
            </div>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="campaign_description">Campaign Description</label>
          <input
            id="campaign_description"
            name="campaign[description]"
            className={classNames('form-control', {
              'has-error': errors.description,
            })}
            value={campaignForm.description}
            placeholder="e.g. meant to be used with a certain trigger like before/after and appointment"
            onChange={this.handleDescriptionChange}
          />

          {errors.description && (
            <div key={uuidv4()}>
              <ErrorMessageWithIcon message={[errors.description]} />
            </div>
          )}
        </div>

        {this.renderCampaignSteps()}

        {selectingTemplate ? (
          <CampaignStepTemplate
            handleFromScratchClick={this.handleFromScratchClick}
            handleCampaignTemplateClick={
              this.handleCampaignTemplateClick
            }
          />
        ) : (
          stepsCount === 0 && (
            <CampaignStepNew
              addStep={this.addStep}
            />
          )
        )}

        <div>
          {this.renderErrors()}
        </div>

        <div className="pull-right">
          <Link to={campaigns_uri} className="btn btn-secondary mr5">
            Cancel and Go Back
          </Link>

          {selectingTemplate === false && (
            <button
              type="submit"
              className={`btn btn-primary ${!validCampaign ? 'disabled' : ''}`}
              disabled={!validCampaign}
            >
              Save Campaign
            </button>
          )}
        </div>
      </form>
    );
  }
}

CampaignForm.defaultProps = {
  campaign:              null,
  campaignSettings:      null,
  validCampaign:         true,
  selectingTemplate:     false,
  onCampaignStateChange: () => {},
  setCampaignErrors:     () => {},
  setErrors:             () => {},
};

CampaignForm.propTypes = {
  history:               PropTypes.shape({}).isRequired,
  campaign:              PropTypes.shape({}),
  campaignSettings:      PropTypes.shape({}),
  validCampaign:         PropTypes.bool,
  selectingTemplate:     PropTypes.bool,
  onCampaignStateChange: PropTypes.func,
  setCampaignErrors:     PropTypes.func,
  setErrors:             PropTypes.func,
};

export default CampaignForm;
